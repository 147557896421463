import React from "react";

export default function SectionsTitle({ title, subtitle }) {
  return (
    <>
      <div className="sec-heading">
        <p className="sec__meta">{subtitle}</p>
        <h2
          className="sec__title"
          style={{ fontSize: "25px", marginTop: "0px" }}
        >
          {title}
        </h2>
      </div>
    </>
  );
}
