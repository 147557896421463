import React, { useState } from "react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "../../assets/css/owl.carousel.css";
import "../../assets/css/owl.theme.default.min.css";
import { FiChevronRight } from "react-icons/fi";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GetInTouchButton from "../../components/about/GetInTouchButton";


export default function HeroSlider({ slideItems }) {
  

  return (
    <section className="hero-slider-wrapper">
          <ToastContainer />
      <OwlCarousel
        className="owl-theme"
        loop={true}
        autoplay={true}
        margin={10}
        dots={false}
        // nav
        items={1}
      >
        {slideItems.map((item, index) => {
          return (
            <div
              key={index}
              className="hero-slider-item"
              style={{ backgroundImage: "url(" + item.bg + ")" }}
            >
              <div className="container">
                <div className="row">
                  <div className={"col-" + item.col}>
                    <div className="hero-slider-content">
                      {item.title ? <h1>{item.title}</h1> : ""}
                      {item.description ? <p>{item.description}</p> : ""}
                    </div>
                    {/* <div className="cta-buttons">
                      <Link
                        to={"/about"}
                        className="theme-button"
                        style={{ backgroundColor: "#C39239" }}
                      >
                        Know about us <FiChevronRight className="icon" />
                      </Link>
                    </div> */}
                    {/* <div  className="touch_form">
        <GetInTouchButton />
        </div> */}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </OwlCarousel>
    </section>
  );
}
