import React from "react";
import Navbar from "../components/common/Navbar";
import Breadcrumb from "../components/common/Breadcrumb";
import Footer from "../components/other/Footer";
import Team from "../components/team/Team";
import sectiondata from "../store/store";
import GetInTouchButton from "../components/about/GetInTouchButton";

function People_Page() {
  return (
    <main>
      <header className="header-area">
        <Navbar />
      </header>
      <div className="get-in-touch-container">
          <GetInTouchButton />
        </div>
      {/* Breadcrumb */}
      {/* <Breadcrumb title="People." /> */}
      {/* leadershipTeamData */}
      <Team
        // secTitle={sectiondata.leadershipTeamData.secTitle}
        secSubtitle={sectiondata.leadershipTeamData.secSubtitle}
        teammembers={sectiondata.leadershipTeamData.teammembers}
      />
      {/*advisoryTeam  */}
      {/* <Team
        // secTitle={sectiondata.advisoryTeam.secTitle}
        secSubtitle={sectiondata.advisoryTeam.secSubtitle}
        teammembers={sectiondata.advisoryTeam.teammembers}
      /> */}
      {/* Footer */}
      {/* <Footer /> */}
    </main>
  );
}

export default People_Page;
