import React,{useMemo} from 'react';
import SectionsTitle from '../common/SectionsTitle';
import Teammembers from "./Teammembers";


export default function Team({ secTitle, secSubtitle, teammembers }) {
  const isMobile = useMemo(() => {
    return window.innerWidth <= 768; 
  }, []);
  return (
    <section className="team-wrapper">
      <div className="container">
        <div className="text-center">
          <SectionsTitle title={secTitle} subtitle={secSubtitle} />
        </div>

        <div className="team-grid text-center">
          <div className="team-container">
            <Teammembers teammembers={teammembers.slice(0, 4)} />
          </div>

          <div className="team-container1" style={{ marginLeft: isMobile ? "0%" : "10%", marginRight: isMobile ? "0%" : "-15%"}}>
            <Teammembers teammembers={teammembers.slice(4)} />
          </div>
        </div>
      </div>
    </section>
  );
}
