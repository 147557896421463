import React from "react";
import Navbar from "../components/common/Navbar";
import Breadcrumb from "../components/common/Breadcrumb";
import ContactDetails from "../components/contact/ContactDetails";
import Footer from "../components/other/Footer";
import GetInTouchButton from "../components/about/GetInTouchButton";

export default function ContactPage() {
  return (
    <>
      {/* Header */}
      <header className="header-area">
        <Navbar />
      </header>
      <div className="get-in-touch-container">
          <GetInTouchButton />
        </div>
      {/* Breadcrumb */}
      {/* <Breadcrumb title="Contact Us." /> */}

      {/* Contact Details */}
      <ContactDetails />

      {/* Footer */}
      {/* <Footer /> */}
    </>
  );
}
