import React, { useEffect, useState } from 'react';
import { useLocation} from 'react-router-dom';
import Navbar from "../components/common/Navbar";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { useHistory } from 'react-router-dom'; 
import logo from "../assets/images/logo.png";

function FeeSummary() {
  const location = useLocation();
  const { selectedFeeModel, selectedApproach, feeDetails, investmentAmount: investmentValue } = location.state || {};
  const [investmentAmount, setInvestmentAmount] = useState(investmentValue || '5000000');
  const [scenarios, setScenarios] = useState([]);
  const history = useHistory();
  const [isMobileView, setIsMobileView] = useState(false);
  const [showContent, setShowContent] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const roundToNearest = (num) => Math.floor(num);
  const handleBackToSelection = () => {
    history.push({
      pathname: '/fee-illustration',  
      state: {
        investmentAmount, 
      },
    });
  };
  const assumptions = {
    a: investmentAmount || 0,
    b: feeDetails?.managementFee || 2.5,
    c: 0.20,
    d: 0.10,
    e:feeDetails?.performanceFee || 2.5,
    f:feeDetails?.hurdleRate || 2.5,
  };

  const formatNumber = (value) => {
    if (value !== undefined && value !== null) {
      const formattedValue = Object.is(value, -0) ? 0 : value;
      if (formattedValue < 0) {
        return `(${Math.abs(formattedValue).toLocaleString()})`;
      }
      return formattedValue.toLocaleString();
    }
    return 'N/A';
  };

  // Calculation logic for the Fixed Fee Model
  const calculateFixedScenario = (capital, scenarioRate, assumptions) => {
    const { b, c, d } = assumptions;
    const gainOrLoss = roundToNearest(capital * scenarioRate);
    const grossValue = roundToNearest(capital + gainOrLoss);
    const averageAUM = roundToNearest((capital + grossValue) / 2);
    const otherExpense = roundToNearest(averageAUM * -(c / 100));
    const brokerageCost = roundToNearest(averageAUM * -(d / 100));
    const managementFees = roundToNearest((averageAUM + otherExpense + brokerageCost) * -(b / 100));
    const gstOnManagementFee = roundToNearest(managementFees * 0.18);
    const totalCharges = roundToNearest(otherExpense + brokerageCost + managementFees + gstOnManagementFee);
    const netValue = roundToNearest(grossValue + totalCharges);
    const portfolioReturn = ((netValue - capital) / capital) * 100;

    return {
      gainOrLoss,
      grossValue,
      averageAUM,
      otherExpense,
      brokerageCost,
      managementFees,
      gstOnManagementFee,
      totalCharges,
      netValue,
      portfolioReturn,
    };
  };

  const calculateMultiYearFixedScenario = (initialCapital, scenarioRates, assumptions, feeDetails) => {
    const { b, c, d } = assumptions; 
    const { performanceFee, hurdleRate } = feeDetails;

    const roundToNearest = (num) => Math.round(num);

    let highWaterMark = initialCapital; 
    let capital = initialCapital;

    return scenarioRates.map((scenarioRate, year) => {
      const gainOrLoss = roundToNearest(capital * scenarioRate);
      const grossValue = roundToNearest(capital + gainOrLoss);
      const averageAUM = roundToNearest((capital + grossValue) / 2);
      const otherExpense = roundToNearest(averageAUM * -(c / 100));
      const brokerageCost = roundToNearest(averageAUM * -(d / 100));
      const managementFees = roundToNearest((averageAUM + otherExpense + brokerageCost) * -(b / 100));
      const gstOnManagementFee = roundToNearest(managementFees * 0.18);
      const totalCharges = roundToNearest(otherExpense + brokerageCost + managementFees + gstOnManagementFee);
      const grossValueBeforePerformanceFee = roundToNearest(grossValue + totalCharges);
      const hurdleRateValue = parseFloat(hurdleRate.replace('%', '')) / 100;
      const HurdleValue = roundToNearest(highWaterMark * (1 + hurdleRateValue));
      const isPerformanceFeeApplicable = grossValueBeforePerformanceFee > HurdleValue;
      const portfolioReturnSubjectToPerformanceFee = roundToNearest(isPerformanceFeeApplicable ? grossValueBeforePerformanceFee - HurdleValue : 0);
      const performanceFeeAmount = roundToNearest(portfolioReturnSubjectToPerformanceFee * (performanceFee / 100));
      const gstOnPerformanceFee = roundToNearest(performanceFeeAmount * 0.18);
      const netValueAfterFees = roundToNearest(grossValueBeforePerformanceFee - performanceFeeAmount - gstOnPerformanceFee);
      const portfolioReturn = ((netValueAfterFees - capital) / capital) * 100;
      highWaterMark = Math.max(HurdleValue, netValueAfterFees);
      const highWaterMarkInvestor = Math.max(HurdleValue, grossValueBeforePerformanceFee);
      capital = netValueAfterFees;

      return {
        year: year + 1,
        gainOrLoss,
        grossValue,
        averageAUM,
        otherExpense,
        brokerageCost,
        managementFees,
        gstOnManagementFee,
        totalCharges,
        grossValueBeforePerformanceFee,
        HurdleValue,
        portfolioReturnSubjectToPerformanceFee,
        performanceFeeAmount,
        gstOnPerformanceFee,
        netValueAfterFees,
        portfolioReturn,
        highWaterMark,
        highWaterMarkInvestor,
        capital,
      };
    });

  };
    
  useEffect(() => {
    const checkMobileView = () => {
      setIsMobileView(window.innerWidth <= 768);
    };
    checkMobileView(); 
    window.addEventListener('resize', checkMobileView);
    return () => window.removeEventListener('resize', checkMobileView);
  }, []);
  
  const calculateScenarios = () => {
  const investmentValue = parseFloat(investmentAmount);

    if (!isNaN(investmentValue) && investmentValue >= 5000000) {
      setErrorMessage('');

      // Generate scenarios based on fee model and approach
      if (selectedFeeModel === "Fixed") {
        const scenario1 = calculateFixedScenario(investmentValue, 0.2, assumptions);
        const scenario2 = calculateFixedScenario(investmentValue, -0.2, assumptions);
        const scenario3 = calculateFixedScenario(investmentValue, 0, assumptions);
        setScenarios([scenario1, scenario2, scenario3]);
      }else if (selectedFeeModel === "MultiYearFixed" && selectedApproach !== "Portfolio Return Optimiser") {
        const scenarioRates = [-0.26, 0.79, 0.22, 0, 0.4];
        const multiYearScenarios = calculateMultiYearFixedScenario(investmentValue, scenarioRates, assumptions, feeDetails);
        setScenarios(multiYearScenarios);
      }else if (selectedFeeModel === "MultiYearHybrid" && selectedApproach === "Portfolio Return Optimiser") {
        const scenarioRates = [-260000, 790000, 220000, 0, 400000];
        const multiYearScenarios = calculateMultiYearHybridPortfolioScenario(investmentValue, scenarioRates, assumptions, feeDetails);
        setScenarios(multiYearScenarios);
      } else if (selectedFeeModel === "Hybrid" && selectedApproach === "Portfolio Return Optimiser") {
        const scenarioRates = [-260000, 0, 220000];
        const multiYearScenarios = calculateHybridPortfolioScenario(investmentValue, scenarioRates, assumptions, feeDetails);
        setScenarios(multiYearScenarios);
      } else if (selectedFeeModel === "Hybrid" && selectedApproach !== "Portfolio Return Optimiser") {
        const scenario1 = calculateHybridScenario(investmentValue, 0.2, assumptions, feeDetails);
        const scenario2 = calculateHybridScenario(investmentValue, -0.2, assumptions, feeDetails);
        const scenario3 = calculateHybridScenario(investmentValue, 0, assumptions, feeDetails);
        setScenarios([scenario1, scenario2, scenario3]);
      } else if (selectedFeeModel === "MultiYearHybrid" && selectedApproach !== "Portfolio Return Optimiser") {
        const scenarioRates = [-0.26, 0.79, 0.22, 0, 0.4];
        const multiYearScenarios = calculateMultiYearHybridScenario(investmentValue, scenarioRates, assumptions, feeDetails);
        setScenarios(multiYearScenarios);
      }
    } else {
      setErrorMessage('Kindly enter an amount above 50 lakhs.');
    }
  };
  useEffect(() => {
    calculateScenarios();
  }, [investmentAmount, selectedFeeModel, selectedApproach, feeDetails]);

  const calculateHybridScenario = (capital, scenarioRate, assumptions, feeDetails, previousHighWaterMark) => {
    const { b, c, d } = assumptions; 
    const { performanceFee, hurdleRate } = feeDetails;
    const roundToNearest = (num) => Math.floor(num);
    const gainOrLoss = roundToNearest(capital * scenarioRate);
    const grossValue = roundToNearest(capital + gainOrLoss);
    const averageAUM = roundToNearest((capital + grossValue) / 2);
    const otherExpense = roundToNearest(averageAUM * -(c / 100));
    const brokerageCost = roundToNearest(averageAUM * -(d / 100));
    const managementFees = roundToNearest((averageAUM + otherExpense + brokerageCost) * -(b / 100)); 
    const gstOnManagementFee = roundToNearest(managementFees * 0.18);
    const totalCharges = roundToNearest(otherExpense + brokerageCost + managementFees + gstOnManagementFee);
    const grossValueBeforePerformanceFee = roundToNearest(grossValue + totalCharges);
    const hurdleRateValue = parseFloat(hurdleRate.replace('%', '')) / 100;
    const HurdleValue = roundToNearest(previousHighWaterMark ? previousHighWaterMark * (1 + hurdleRateValue) : capital * (1 + hurdleRateValue));
    const isPerformanceFeeApplicable = grossValueBeforePerformanceFee > HurdleValue;
    const portfolioReturnSubjectToPerformanceFee = roundToNearest(isPerformanceFeeApplicable ? (grossValueBeforePerformanceFee - HurdleValue) : 0);
    const performanceFeeAmount = roundToNearest(portfolioReturnSubjectToPerformanceFee * -(performanceFee / 100));
    const gstOnPerformanceFee = roundToNearest(performanceFeeAmount * 0.18);
    const netValueAfterFees = roundToNearest(grossValueBeforePerformanceFee +performanceFeeAmount +gstOnPerformanceFee);
    const portfolioReturn = ((netValueAfterFees - capital) / capital) * 100;
    const highWaterMarkFromPortfolio = Math.max(HurdleValue, netValueAfterFees);
    const highWaterMarkFromInvestor = Math.max(grossValueBeforePerformanceFee, HurdleValue);
  
    return {
      gainOrLoss,
      grossValue,
      averageAUM,
      otherExpense,
      brokerageCost,
      managementFees,
      gstOnManagementFee,
      totalCharges,
      grossValueBeforePerformanceFee,
      HurdleValue,
      portfolioReturnSubjectToPerformanceFee,
      performanceFeeAmount,
      gstOnPerformanceFee,
      netValueAfterFees,
      portfolioReturn, 
      highWaterMarkFromPortfolio,
      highWaterMarkFromInvestor,
    };
  };

  const calculateMultiYearHybridScenario = (initialCapital, scenarioRates, assumptions, feeDetails) => {
    const { b, c, d } = assumptions; 
    const { performanceFee, hurdleRate } = feeDetails;

    const roundToNearest = (num) => Math.round(num);

    let highWaterMark = initialCapital; 
    let capital = initialCapital;

    return scenarioRates.map((scenarioRate, year) => {
      const gainOrLoss = roundToNearest(capital * scenarioRate);
      const grossValue = roundToNearest(capital + gainOrLoss);
      const averageAUM = roundToNearest((capital + grossValue) / 2);
      const otherExpense = roundToNearest(averageAUM * -(c / 100));
      const brokerageCost = roundToNearest(averageAUM * -(d / 100));
      const managementFees = roundToNearest((averageAUM + otherExpense + brokerageCost) * -(b / 100));
      const gstOnManagementFee = roundToNearest(managementFees * 0.18);
      const totalCharges = roundToNearest(otherExpense + brokerageCost + managementFees + gstOnManagementFee);
      const grossValueBeforePerformanceFee = roundToNearest(grossValue + totalCharges);
      const hurdleRateValue = parseFloat(hurdleRate.replace('%', '')) / 100;
      const HurdleValue = roundToNearest(highWaterMark * (1 + hurdleRateValue));
      const isPerformanceFeeApplicable = grossValueBeforePerformanceFee > HurdleValue;
      const portfolioReturnSubjectToPerformanceFee = roundToNearest(isPerformanceFeeApplicable ? grossValueBeforePerformanceFee - HurdleValue : 0);
      const performanceFeeAmount = roundToNearest(portfolioReturnSubjectToPerformanceFee * (performanceFee / 100));
      const gstOnPerformanceFee = roundToNearest(performanceFeeAmount * 0.18);
      const netValueAfterFees = roundToNearest(grossValueBeforePerformanceFee - performanceFeeAmount - gstOnPerformanceFee);
      const portfolioReturn = ((netValueAfterFees - capital) / capital) * 100;
      highWaterMark = Math.max(HurdleValue, netValueAfterFees);
      const highWaterMarkInvestor = Math.max(HurdleValue, grossValueBeforePerformanceFee);
      capital = netValueAfterFees;

      return {
        year: year + 1,
        gainOrLoss,
        grossValue,
        averageAUM,
        otherExpense,
        brokerageCost,
        managementFees,
        gstOnManagementFee,
        totalCharges,
        grossValueBeforePerformanceFee,
        HurdleValue,
        portfolioReturnSubjectToPerformanceFee,
        performanceFeeAmount,
        gstOnPerformanceFee,
        netValueAfterFees,
        portfolioReturn,
        highWaterMark,
        highWaterMarkInvestor,
        capital,
      };
    });

  };

  const calculateMultiYearHybridPortfolioScenario = (initialCapital, scenarioRates, assumptions, feeDetails) => {
    const { b,c,d } = assumptions; 
    const { performanceFee,managementFee } = feeDetails; 

    const roundToNearest = (num) => Math.round(num); 
    let capital = initialCapital; 
    let previousYearLoss = 0;

    return scenarioRates.map((gainOrLoss, year) => {
        const i = year === 0 ? initialCapital : capital;

        const ii = roundToNearest(gainOrLoss);

        const iii = roundToNearest(i + ii);

        const iv = roundToNearest((i + iii) / 2);
        const v = roundToNearest(iv * -(c / 100));
        const vi = roundToNearest(iv * -(d / 100));
        const vii = roundToNearest((iv + v + vi) * -(b / 100));

        const viii = roundToNearest(vii * 0.18);

        const ix = roundToNearest(v + vi + vii + viii);

        const x = roundToNearest(iii + ix);

        const xi = ii + vii > 0?"Yes":"No";

        const xii = ii + vii > 0 ? roundToNearest(ii + vii) : 0;

        const xiii = previousYearLoss < 0 ? previousYearLoss : 0;

        const xiv = roundToNearest(xii + xiii);

        const xv = roundToNearest(xiv * (performanceFee / 100));

        const xvi = roundToNearest(xv * 0.18);

        const xvii = roundToNearest(x - xv - xvi);

        const xviii = ii < 0 ? ii : 0;

        capital = xvii;
        previousYearLoss = xviii;
        return {
            i, ii, iii, iv, v, vi, vii, viii, ix, x, xi, xii, xiii, xiv, xv, xvi, xvii, xviii,
        };
    });
};

const calculateHybridPortfolioScenario = (initialCapital, scenarioRates, assumptions, feeDetails) => {
  const { b,c,d } = assumptions;
  const { performanceFee,managementFee } = feeDetails; 

  const roundToNearest = (num) => Math.round(num); 
  let capital = initialCapital;
  let previousYearLoss = 0;

  return scenarioRates.map((gainOrLoss, year) => {
      const i = initialCapital;
      const ii = roundToNearest(gainOrLoss);
      const iii = roundToNearest(i + ii);
      const iv = roundToNearest((i + iii) / 2);
      const v = roundToNearest(iv * -(c / 100));
      const vi = roundToNearest(iv * -(d / 100));
      const vii = roundToNearest((iv + v + vi) * -(b / 100));
      const viii = roundToNearest(vii * 0.18);
      const ix = roundToNearest(v + vi + vii + viii);
      const x = roundToNearest(iii + ix);
      const xi = ii + vii > 0?"Yes":"No";
      const xii = ii + vii > 0? roundToNearest(ii + vii) : 0;
      const xiii =  roundToNearest(xii * -(performanceFee / 100));
      const xiv = roundToNearest(xiii * 0.18);
      const xv =  roundToNearest(x +xiii+xiv);
      return {
          i, ii, iii, iv, v, vi, vii, viii, ix, x, xi, xii, xiii, xiv, xv,
      };
  });
};
const downloadPDF = () => {
  setShowContent(false); 
  const pdfContent = document.getElementById('pdf-content');
  pdfContent.style.display = 'block';
  pdfContent.classList.add('pdf-download-format');

  html2canvas(pdfContent, {
    scale: 2, 
    useCORS: true,
  }).then(canvas => {
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'mm', 'a4');
    const imgWidth = 190;
    const pageHeight = 297;
    let imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    let position = 0;

    pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft > 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    const pdfBlob = pdf.output('blob');
    const pdfURL = URL.createObjectURL(pdfBlob);

    if (window.innerWidth <= 768) {
      const link = document.createElement('a');
      link.href = pdfURL;
      link.download = 'Fee_Summary.pdf';  
      link.click();
    } else {
      window.open(pdfURL, '_blank');
    }
    pdfContent.style.display = 'none';
    pdfContent.classList.remove('pdf-download-format');
  }).catch(error => {
    console.error('Error generating PDF:', error);
    setShowContent(true);  
  });
};

  useEffect(() => {
    if (scenarios.length > 0) {
      downloadPDF( setShowContent(false));
    }
  }, [scenarios]);
  if (!showContent) {
    history.push('/fee-illustration');
    return null;
  }

  
  return (
    <main>
    <div>
      <header className="header-area">
        <Navbar />
      </header>
      <div className="fee-model-container">
        {/* Fixed Fee Model Scenario Table */}
        {selectedFeeModel === "Fixed" && scenarios.length > 0 && (
          <div id="pdf-content" className="desktop-content">
            <div className="header-container">
                  <div className="logo-container">
                    <img src={logo} alt="Agreya Logo" className="logo" />
                  </div>
                  <h2 className="table-h">FIXED FEE MODEL - {selectedApproach}</h2>
                </div>           
                <table className="assumptions-table">
                          <thead>
                            <tr>
                              <th colspan="3">Assumptions</th>
                            
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Capital Contribution (Rs.)</td>
                              <td>a</td>
                              <td>{formatNumber(assumptions.a)}</td>
                            </tr>
                            <tr>
                              <td>Management Fee (%age per annum)
                              </td>
                              <td>b</td>
                              <td>{assumptions.b}%</td>
                            </tr>
                            <tr>
                              <td>Other Expenses (% pa including GST)</td>
                              <td>c</td>
                              <td>{assumptions.c}%</td>
                            </tr>
                            <tr>
                              <td>Brokerage and Transaction cost (% pa including GST)</td>
                              <td>d</td>
                              <td>{assumptions.d}%</td>
                            </tr>
                          </tbody>
                        </table>
                        <table className="scenario-table">
                            <thead>
                              <tr>
                                <th>Fee Illustration</th>
                                <th>Variable</th>
                                <th>Formula</th>
                                <th>Scenario 1<br/>Gain of 20%</th>
                                <th>Scenario 2<br/>Loss of 20%</th>
                                <th>Scenario 3<br/>Nil Gain/Loss</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Capital Contributed / Assets under Management</td>
                                <td>i</td>
                                <td>i = a</td>
                                {scenarios.map((_, index) => (
                                  <td key={index}>{formatNumber(assumptions.a)}</td>
                                ))}
                              </tr>
                              <tr>
                                <td>Gain / (Loss) on Investment based on the Scenario</td>
                                <td>ii</td>
                                <td>ii = i * Scenario</td>
                                {scenarios.map((scenario, index) => (
                                  <td key={index}>
                                    {formatNumber(scenario.gainOrLoss)}
                                  </td>
                                ))}
                              </tr>
                              <tr>
                                <td>Gross Value of the Portfolio at the end of the year</td>
                                <td>iii</td>
                                <td>iii = i + ii</td>
                                {scenarios.map((scenario, index) => (
                                  <td key={index}>{formatNumber(scenario.grossValue)}</td>
                                ))}
                              </tr>
                              <tr>
                                <td>Average assets under management</td>
                                <td>iv</td>
                                <td>iv = (i + iii) / 2</td>
                                {scenarios.map((scenario, index) => (
                                  <td key={index}>{formatNumber(scenario.averageAUM)}</td>
                                ))}
                              </tr>
                              <tr>
                                <td>Other Expense</td>
                                <td>v</td>
                                <td>v = iv * c</td>
                                {scenarios.map((scenario, index) => (
                                  <td key={index} >
                                    {formatNumber(scenario.otherExpense)}</td>
                                ))}
                              </tr>
                              <tr>
                                <td>Brokerage and Transaction cost</td>
                                <td>vi</td>
                                <td>vi = iv * d</td>
                                {scenarios.map((scenario, index) => (
                                  <td key={index} >
                                    {formatNumber(scenario.brokerageCost)}</td>
                                ))}
                              </tr>
                              <tr>
                                <td>Management Fees</td>
                                <td>vii</td>
                                <td>vii = (iv + v + vi) * b</td>
                                {scenarios.map((scenario, index) => (
                                  <td key={index} >
                                    {formatNumber(scenario.managementFees)}</td>
                                ))}
                              </tr>
                              <tr>
                                <td>GST on Management Fees</td>
                                <td>viii</td>
                                <td>viii = vii * 18%</td>
                                {scenarios.map((scenario, index) => (
                                  <td key={index} >
                                    {formatNumber(scenario.gstOnManagementFee)}</td>
                                ))}
                              </tr>
                              <tr>
                                <td>Total charges during the year</td>
                                <td>ix</td>
                                <td>ix = v + vi + vii + viii</td>
                                {scenarios.map((scenario, index) => (
                                  <td key={index}>
                                    {formatNumber(scenario.totalCharges)}</td>
                                ))}
                              </tr>
                              <tr>
                                <td>Net value of the Portfolio at the end of the year</td>
                                <td>x</td>
                                <td>x = iii + ix</td>
                                {scenarios.map((scenario, index) => (
                                  <td key={index}>{formatNumber(scenario.netValue)}</td>
                                ))}
                              </tr>
                              <tr>
                                <td>% Portfolio Return</td>
                                <td>xi</td>
                                <td>xi = ((x - i) / i) %</td>
                                {scenarios.map((scenario, index) => (
                                  <td key={index}>{scenario.portfolioReturn !== undefined ? scenario.portfolioReturn.toFixed(2) : 'N/A'}%</td>
                                ))}
                              </tr>
                            </tbody>
                          </table>

                          <div className="notes-section">
                          <h4>Notes:</h4>
                            <li>&bull; Returns are assumed to be generated linearly through the year.</li>
                            <li>&bull; Other Expenses includes Account Opening charges, stamp duty /Audit Fee/ Bank charges / Fund Accounting charges / Custody Fee / demat charges or other miscellaneous expense</li>
                            <li>&bull; Brokerage and transaction cost for the illustration purpose is charged on the Average AUM. However, Brokerage and Transaction cost are charged on basis the actuals trades. </li>
                            <li>&bull; All Fees and charges are subject to GST at 18% or as per extant regulations</li>
                            <li>&bull; All Amounts are in INR.</li>
                        </div>
                        <div className="notes-section">
              <h6>Disclaimer:</h6>
              <p style={{ fontStyle: "italic", fontWeight: "normal", fontSize: "12px", lineHeight: "1.5", marginTop: "10px" }}>
              This document has been prepared by Agreya Capital Advisors LLP (“Agreya”), a SEBI registered Portfolio Manager (SEBI Registration Number INP000006077) for information purposes only. No liability is accepted by Agreya or its representative whatsoever for any direct, indirect or consequential loss arising from the use of this document. This document is not intended as an offer or publication or solicitation for distribution or for subscription of or purchase or sale of any securities or financial instruments(s) to anyone. The information contained in this document shall not be published, reproduced or disseminated without specific confirmation from Agreya. Any person/s accessing data contained in this presentation, hereby confirms that they are not a U.S. person, within the definition of the term "US Person" under the US Securities laws.
            </p>

            </div>
                      </div>
                    )}

        {/* Hybrid Fee Model Scenario Table */}
        {selectedFeeModel === "Hybrid" && selectedApproach !== "Portfolio Return Optimiser"  && scenarios.length > 0 && (
          <div id="pdf-content" className="desktop-content">
            <div className="header-container">
              <div className="logo-container">
                <img src={logo} alt="Agreya Logo" className="logo" />
              </div>
              <h2 className="table-h">HYBRID FEE MODEL - {selectedApproach}</h2>
            </div> 
            <table className="assumptions-table">
        <thead>
          <tr>
            <th colSpan={3}>Assumptions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Capital Contribution (Rs.)</td>
            <td>a</td>
            <td>{formatNumber(assumptions.a)}</td>
          </tr>
          <tr>
            <td>Management Fee (%age per annum)</td>
            <td>b</td>
            <td>{assumptions.b}%</td>
          </tr>
          <tr>
            <td>Other Expenses (% pa including GST)</td>
            <td>c</td>
            <td>{assumptions.c}%</td>
          </tr>
          <tr>
            <td>Performance Fee(%age per annum)</td>
            <td>d</td>
            <td>{assumptions.e}%</td>
          </tr>
          <tr>
            <td>Hurdle Rate of Return (%age per annum)</td>
            <td>e</td>
            <td>{assumptions.f}</td>
          </tr>
          <tr>
            <td>Brokerage and Transaction cost (% pa including GST)</td>
            <td>f</td>
            <td>{assumptions.d}%</td>
          </tr>
        </tbody>
      </table>
          
      <table className="scenario-table">
      <thead>
        <tr>
          <th>Fee Illustration</th>
          <th>Variable</th>
          <th>Formula</th>
          <th>Scenario 1<br/>Gain of 20%</th>
          <th>Scenario 2<br/>Loss of 20%</th>
          <th>Scenario 3<br/>Nil Gain/Loss</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Capital Contributed / Assets under Management </td>
          <td>i</td>
          <td>i = a</td>
          {scenarios.map((_, index) => (
            <td key={index}>{formatNumber(assumptions.a)}</td>
          ))}
        </tr>
        <tr>
          <td>Gain / (Loss) on Investment based on the Scenario </td>
          <td>ii</td>
          <td>ii = i * Scenario</td>
          {scenarios.map((scenario, index) => (
            <td key={index}>
              {formatNumber(scenario.gainOrLoss)}
            </td>
          ))}
        </tr>
        <tr>
          <td>Gross Value of the Portfolio at the end of the year </td>
          <td>iii</td>
          <td>iii = i + ii</td>
          {scenarios.map((scenario, index) => (
            <td key={index}>{formatNumber(scenario.grossValue)}</td>
          ))}
        </tr>
        <tr>
          <td>Daily Weighted Average assets under management </td>
          <td>iv</td>
          <td>iv = (i + iii) / 2</td>
          {scenarios.map((scenario, index) => (
            <td key={index}>{formatNumber(scenario.averageAUM)}</td>
          ))}
        </tr>
        <tr>
          <td>Other Expense</td>
          <td>v</td>
          <td>v = iv * c</td>
          {scenarios.map((scenario, index) => (
            <td key={index}>{formatNumber(scenario.otherExpense)}</td>
          ))}
        </tr>
        <tr>
          <td>Brokerage and Transaction cost</td>
          <td>vi</td>
          <td>vi = iv * f</td>
          {scenarios.map((scenario, index) => (
            <td key={index}>{formatNumber(scenario.brokerageCost)}</td>
          ))}
        </tr>
        <tr>
          <td>Management Fees </td>
          <td>vii</td>
          <td>vii = (iv + v + vi) * b</td>
          {scenarios.map((scenario, index) => (
            <td key={index}>{formatNumber(scenario.managementFees)}</td>
          ))}
        </tr>
        <tr>
          <td>GST on Management Fee</td>
          <td>viii</td>
          <td>viii = vii * 18%</td>
          {scenarios.map((scenario, index) => (
            <td key={index}>{formatNumber(scenario.gstOnManagementFee)}</td>
          ))}
        </tr>
        <tr>
          <td>Total charges excluding Performance fee</td>
          <td>ix</td>
          <td>ix = v + vi + vii + viii</td>
          {scenarios.map((scenario, index) => (
            <td key={index}>{formatNumber(scenario.totalCharges)}</td>
          ))}
        </tr>
        <tr>
          <td>Gross Value of the Portfolio before Performance fee</td>
          <td>x</td>
          <td>x = iii + ix</td>
          {scenarios.map((scenario, index) => (
            <td key={index}>{formatNumber(scenario.grossValueBeforePerformanceFee)}</td>
          ))}
        </tr>
        <tr>
          <td>Hurdle Value</td>
          <td>xi</td>
          <td>xi = i *(1+e)</td>
          {scenarios.map((scenario, index) => (
            <td key={index}>{formatNumber(scenario.HurdleValue)}</td>
          ))}
        </tr>
        <tr>
          <td>Performance Fee Applicability</td>
          <td>xii</td>
          <td>xii = x {'>'} xi, then Yes else No Pfee</td>
          {scenarios.map((scenario, index) => (
            <td key={index}>{scenario.grossValueBeforePerformanceFee > scenario.HurdleValue ? "Yes" : "No"}</td>
          ))}
        </tr>
        <tr>
          <td  colSpan={3}>
        If Yes, proceed to performance fee calculation else 0 (zero) performance fee for the period </td>
        </tr>
        <tr>
          <td>Portfolio return eligible for Performance Fee</td>
          <td>xiii</td>
          <td>xiii = x - xi</td>
          {scenarios.map((scenario, index) => (
            <td key={index}>{formatNumber(scenario.portfolioReturnSubjectToPerformanceFee)}</td>
          ))}
        </tr>
        <tr>
          <td>Performance fee</td>
          <td>xiv</td>
          <td>xiv = xiii * d</td>
          {scenarios.map((scenario, index) => (
            <td key={index}>{formatNumber(scenario.performanceFeeAmount)}</td>
          ))}
        </tr>
        <tr>
          <td>GST on Performance Fee</td>
          <td>xv</td>
          <td>xv = xiv * 18%</td>
          {scenarios.map((scenario, index) => (
            <td key={index}>{formatNumber(scenario.gstOnPerformanceFee)}</td>
          ))}
        </tr>
        <tr>
          <td>Net Value of the Portfolio at the end of the year after all fees</td>
          <td>xvi</td>
          <td>xvi = x + xiv + xv</td>
          {scenarios.map((scenario, index) => (
            <td key={index}>{formatNumber(scenario.netValueAfterFees)}</td>
          ))}
        </tr>
        <tr>
          <td>% Portfolio Return for the year</td>
          <td>xvii</td>
          <td>xvii = ((xvi - i) / i) %</td>
          {scenarios.map((scenario, index) => (
            <td key={index}>{scenario.portfolioReturn !== undefined ? scenario.portfolioReturn.toFixed(2) : 'N/A'}%</td>
          ))}
        </tr>
        <tr>
          <td>High Water Mark to be carried forward for next year (when performance fee is charged to the portfolio)</td>
          <td>xviii</td>
          <td>xviii = Max(xi, xvi)</td>
          {scenarios.map((scenario, index) => (
            <td key={index}>{formatNumber(scenario.highWaterMarkFromPortfolio)}</td>
          ))}
        </tr>
        <tr>
          <td>High Water Mark to be carried forward for next year (when performance fee is paid separately by investor)</td>
          <td>xix</td>
          <td>xix = Max(x, xi)</td>
          {scenarios.map((scenario, index) => (
            <td key={index}>{formatNumber(scenario.highWaterMarkFromInvestor)}</td>
          ))}
        </tr>
      </tbody>
    </table>
          <div className="notes-section">
              <h4>Notes:</h4>
                <li>&bull; Returns are assumed to be generated linearly through the year.</li>
                <li>&bull; Other Expense includes Account Opening charges, stamp duty /Audit Fee/ Bank charges / Fund Accounting charges / Custody Fee / demat charges or other miscellaneous expense.</li>
                <li>&bull; Brokerage and transaction cost for the illustration purpose is charged on the Average AUM. However, Brokerage and Transaction cost are charged on basis the actuals trades. </li>
                <li>&bull; All Fees and charges are subject to GST at 18% or as per extant regulations</li>
                <li>&bull; Hurdle computation is prorated in case the performance fee period is less than 1 year OR if there are inflow/outflows from the portfolio.</li>
                <li>&bull; All Amounts are in INR</li>
            </div>
            <div className="notes-section">
  <h6>Disclaimer:</h6>
  <p style={{ fontStyle: "italic", fontWeight: "normal", fontSize: "12px", lineHeight: "1.5", marginTop: "10px" }}>
  This document has been prepared by Agreya Capital Advisors LLP (“Agreya”), a SEBI registered Portfolio Manager (SEBI Registration Number INP000006077) for information purposes only. No liability is accepted by Agreya or its representative whatsoever for any direct, indirect or consequential loss arising from the use of this document. This document is not intended as an offer or publication or solicitation for distribution or for subscription of or purchase or sale of any securities or financial instruments(s) to anyone. The information contained in this document shall not be published, reproduced or disseminated without specific confirmation from Agreya. Any person/s accessing data contained in this presentation, hereby confirms that they are not a U.S. person, within the definition of the term "US Person" under the US Securities laws.
</p>

</div>
          </div>
        )}

{selectedFeeModel === "Hybrid" && selectedApproach === "Portfolio Return Optimiser" && scenarios.length > 0 && (
          <div id="pdf-content" className="desktop-content">
              <div className="header-container">
                <div className="logo-container">
                  <img src={logo} alt="Agreya Logo" className="logo" />
                </div>
                <h2 className="table-h">HYBRID FEE MODEL - {selectedApproach}</h2>
              </div> 
              <table className="assumptions-table">
              <thead>
                <tr>
                  <th colSpan={3}>Assumptions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Capital Contribution</td>
                  <td>a</td>
                  <td>{formatNumber(assumptions.a)}</td>
                </tr>
                <tr>
                  <td>Cash Component out of Total Capital</td>
                  <td>b</td>
                  <td>{formatNumber(100000)}</td>
                </tr>
                <tr>
                  <td>Management Fee (%age per annum)</td>
                  <td>c</td>
                  <td>{assumptions.b}%</td>
                </tr>
                <tr>
                  <td>Other Expenses (% pa including GST)</td>
                  <td>d</td>
                  <td>{assumptions.c}%</td>
                </tr>
                <tr>
                  <td>Brokerage and Transaction cost (% pa including GST)</td>
                  <td>e</td>
                  <td>{assumptions.d}%</td>
                </tr>
                <tr>
                  <td>Performance Fee(%age per annum)</td>
                  <td>f</td>
                  <td>{assumptions.e}%</td>
                </tr>
                
              </tbody>
              
            </table>
 
              <table className="scenario-table">
              <thead>
                      <tr>
                        <th>Fee Illustration</th>
                        <th>Variable</th>
                        <th>Formula</th>
                        <th>Scenario 1<br/>Loss of Rs. 260,000</th>
                        <th>Scenario 2<br/>Nil Gain/Loss</th>
                        <th>Scenario 3<br/>Gain of Rs. 220,000</th>
                      </tr>
                    </thead>

            <tbody>
              {[
                { label: 'Capital Contributed / Assets under Management', key: 'i', formula: 'i = a' },
                { label: 'Derivative Income/All Income during the year', key: 'ii', formula: 'ii = Gain / (Loss)' },
                { label: 'Gross Value of the Portfolio at the end of the year', key: 'iii', formula: 'iii = i + ii' },
                { label: 'Daily Weighted Average assets under management', key: 'iv', formula: 'iv = (i + iii) / 2' },
                { label: 'Other Expense', key: 'v', formula: 'v = iv * d' },
                { label: 'Brokerage and Transaction cost', key: 'vi', formula: 'vi = iv * e' },
                { label: 'Management Fees', key: 'vii', formula: 'vii = (iv + v + vi) * c' },
                { label: 'GST on Management Fees', key: 'viii', formula: 'viii = vii * 18%' },
                { label: 'Total charges excluding performance fee', key: 'ix', formula: 'ix = v + vi + vii + viii' },
                { label: 'Gross Value of the Portfolio before Performance fee', key: 'x', formula: 'x = iii + ix' },
                { label: 'Performance Fee Applicability', key: 'xi', formula: 'If(ii+vii) > 0, then Yes else No' },
                { label: 'Portfolio return subject to Performance Fee', key: 'xii', formula: 'If xi=Yes,then (ii+vii) else 0' },
                { label: 'Performance Fee', key: 'xiii', formula: 'xiii = xii * f' },
                { label: 'GST on Performance Fee (Rs.)', key: 'xiv', formula: 'xiv = xiii * 18%' },
                { label: 'Net value of the Portfolio at the end of the year after all fees and expenses', key: 'xv', formula: 'xvii = x + xii + xiv' },
              ].map((row, rowIndex) => (
                <tr key={`row-${rowIndex}`}>
                  <td>{row.label}</td>
                  <td>{row.key}</td>
                  <td>{row.formula}</td>
                  {scenarios.map((scenario, index) => (
                    <td key={`value-${row.key}-${index}`}>{formatNumber(scenario[row.key])}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
                <div className="notes-section">
                      <h4>Notes:</h4>
                        <li>&bull; Other Expense includes Account Opening charges, stamp duty /Audit Fee/ Bank charges / Fund Accounting charges / Custody Fee / demat charges or other miscellaneous expense</li>
                        <li>&bull; Brokerage and transaction cost for the illustration purpose is charged on the Average AUM. However, Brokerage and Transaction cost are charged on basis the actuals trades. </li>
                        <li>&bull; All Fees and charges are subject to GST at 18% or as per extant regulations</li>
                        <li>&bull; All Amounts are in INR.</li>
                    </div>
                    <div className="notes-section">
                      <h6>Disclaimer:</h6>
                      <p style={{ fontStyle: "italic", fontWeight: "normal", fontSize: "12px", lineHeight: "1.5", marginTop: "10px" }}>
                      This document has been prepared by Agreya Capital Advisors LLP (“Agreya”), a SEBI registered Portfolio Manager (SEBI Registration Number INP000006077) for information purposes only. No liability is accepted by Agreya or its representative whatsoever for any direct, indirect or consequential loss arising from the use of this document. This document is not intended as an offer or publication or solicitation for distribution or for subscription of or purchase or sale of any securities or financial instruments(s) to anyone. The information contained in this document shall not be published, reproduced or disseminated without specific confirmation from Agreya. Any person/s accessing data contained in this presentation, hereby confirms that they are not a U.S. person, within the definition of the term "US Person" under the US Securities laws.
                     </p>

                    </div>
                  </div>   
                )}

                {/* Multi-Year Hybrid Fee Model Scenario Table */}
                {selectedFeeModel === "MultiYearHybrid"  && selectedApproach !== "Portfolio Return Optimiser" && scenarios.length > 0 && (
                  <div id="pdf-content" className="desktop-content">
                    <div className="header-container">
                      <div className="logo-container">
                        <img src={logo} alt="Agreya Logo" className="logo" />
                      </div>
                      <h2 className="table-h">HYBRID FEE MODEL - {selectedApproach}</h2>
                    </div> 
                    <table className="assumptions-table">
                <thead>
                  <tr>
                    <th colSpan={3}>Assumptions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Capital Contribution (Rs.)</td>
                    <td>a</td>
                    <td>{formatNumber(assumptions.a)}</td>
                  </tr>
                  <tr>
                    <td>Management Fee (%age per annum)</td>
                    <td>b</td>
                    <td>{assumptions.b}%</td>
                  </tr>
                  <tr>
                    <td>Other Expenses (% pa including GST)</td>
                    <td>c</td>
                    <td>{assumptions.c}%</td>
                  </tr>
                  <tr>
                    <td>Performance Fee(%age per annum)</td>
                    <td>d</td>
                    <td>{assumptions.e}%</td>
                  </tr>
                  <tr>
                    <td>Hurdle Rate of Return (%age per annum)</td>
                    <td>e</td>
                    <td>{assumptions.f}</td>
                  </tr>
                  <tr>
                    <td>Brokerage and Transaction cost (% pa including GST)</td>
                    <td>f</td>
                    <td>{assumptions.d}%</td>
                  </tr>
                </tbody>
                
              </table>
                  
                  <table className="scenario-table">
                  <thead>
                  <tr>
                    <th>Fee Illustration</th>
                    <th>Variable</th>
                    <th>Formula</th>
                    <th>Yr 1<br/>Loss of 26%</th>
                    <th>Yr 2<br/>Gain of 79%</th>
                    <th>Yr 3<br/>Gain of 22%</th>
                    <th>Yr 4<br/>Nil Gain/Loss</th>
                    <th>Yr 5<br/>Gain of 40%</th>
                  </tr>
                </thead>

                  <tbody>
                    <tr>
                      <td>Capital Contributed /Assets under Management</td>
                      <td>i</td>
                      <td>i=a (for 1st year);<br/> i=xvi(from 2nd year onwards)</td>
                      {scenarios.map((scenario, index) => (
                        <td key={`value-i-${index}`}>{formatNumber(index === 0 ? assumptions.a : scenarios[index - 1].netValueAfterFees)}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>Gain / (Loss) on Investment based on the Scenario </td>
                      <td>ii</td>
                      <td>ii = i * Scenario</td>
                      {scenarios.map((scenario, index) => (
                        <td key={`value-ii-${index}`}>{formatNumber(scenario.gainOrLoss)}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>Gross Value of the Portfolio at the end of the year </td>
                      <td>iii</td>
                      <td>iii = i + ii</td>
                      {scenarios.map((scenario, index) => (
                        <td key={`value-iii-${index}`}>{formatNumber(scenario.grossValue)}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>Daily Weighted Average assets under management </td>
                      <td>iv</td>
                      <td>iv = (i + iii) / 2</td>
                      {scenarios.map((scenario, index) => (
                        <td key={`value-iv-${index}`}>{formatNumber(scenario.averageAUM)}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>Other Expense</td>
                      <td>v</td>
                      <td>v = iv * c</td>
                      {scenarios.map((scenario, index) => (
                        <td key={`value-v-${index}`}>{formatNumber(scenario.otherExpense)}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>Brokerage and Transaction cost</td>
                      <td>vi</td>
                      <td>vi = iv * f</td>
                      {scenarios.map((scenario, index) => (
                        <td key={`value-vi-${index}`}>{formatNumber(scenario.brokerageCost)}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>Management Fees</td>
                      <td>vii</td>
                      <td>vii = (iv + v + vi) * b</td>
                      {scenarios.map((scenario, index) => (
                        <td key={`value-vii-${index}`}>{formatNumber(scenario.managementFees)}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>GST on Management Fees</td>
                      <td>viii</td>
                      <td>viii = vii * 18%</td>
                      {scenarios.map((scenario, index) => (
                        <td key={`value-viii-${index}`}>{formatNumber(scenario.gstOnManagementFee)}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>Total charges before Performance fee</td>
                      <td>ix</td>
                      <td>ix = v + vi + vii + viii</td>
                      {scenarios.map((scenario, index) => (
                        <td key={`value-ix-${index}`}>{formatNumber(scenario.totalCharges)}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>Gross Value of the Portfolio before Performance fee</td>
                      <td>x</td>
                      <td>x = iii + ix</td>
                      {scenarios.map((scenario, index) => (
                        <td key={`value-x-${index}`}>{formatNumber(scenario.grossValueBeforePerformanceFee)}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>Hurdle Value</td>
                      <td>xi</td>
                      <td>1st year  xi = i *(1+e);<br/>2nd year onwards<br/> xi=Previous years xvii*(1+e)</td>
                      {scenarios.map((scenario, index) => (
                        <td key={`value-xi-${index}`}>{formatNumber(scenario.HurdleValue)}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>Performance Fee Applicability</td>
                      <td>xii</td>
                      <td>xii = x {">"} xi then Yes else No</td>
                      {scenarios.map((scenario, index) => (
                        <td key={`value-xii-${index}`}>{scenario.grossValueBeforePerformanceFee > scenario.HurdleValue ? "Yes" : "No"}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>Portfolio return subject to Performance Fee</td>
                      <td>xiii</td>
                      <td>xiii = x - xi</td>
                      {scenarios.map((scenario, index) => (
                        <td key={`value-xiii-${index}`}>{formatNumber(scenario.portfolioReturnSubjectToPerformanceFee)}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>Performance Fee</td>
                      <td>xiv</td>
                      <td>xiv = xiii * d</td>
                      {scenarios.map((scenario, index) => (
                        <td key={`value-xiv-${index}`}>{formatNumber(scenario.performanceFeeAmount)}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>GST on Performance Fee (Rs.)</td>
                      <td>xv</td>
                      <td>xv = xiv * 18%</td>
                      {scenarios.map((scenario, index) => (
                        <td key={`value-xv-${index}`}>{formatNumber(scenario.gstOnPerformanceFee)}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>Net value of the Portfolio at the end of the year after all fees and expenses</td>
                      <td>xvi</td>
                      <td>xvi = x+xiv+xv</td>
                      {scenarios.map((scenario, index) => (
                        <td key={`value-xvi-${index}`}>{formatNumber(scenario.netValueAfterFees)}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>% Portfolio Return  for the year</td>
                      <td>xvii</td>
                      <td>xvii = ((xvi - i) / i) %</td>
                      {scenarios.map((scenario, index) => (
                        <td key={`value-xvii-${index}`}>{scenario.portfolioReturn !== undefined ? scenario.portfolioReturn.toFixed(2) : 'N/A'}%</td>
                      ))}
                    </tr>
                    <tr>
                      <td>High Water Mark to be carried forward for next year (when performance fee is charged to the portfolio)</td>
                      <td>xviii</td>
                      <td>xviii = Max(xi, xvi)</td>
                      {scenarios.map((scenario, index) => (
                        <td key={`value-xviii-${index}`}>{formatNumber(scenario.highWaterMark)}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>High Water Mark to be carried forward for next year (when performance fee is paid separately by investor in that particular year)</td>
                      <td>xix</td>
                      <td>xix = Max(x, xi)</td>
                      {scenarios.map((scenario, index) => (
                        <td key={`value-xix-${index}`}>{formatNumber(scenario.highWaterMarkInvestor)}</td>
                      ))}
                    </tr>
                  </tbody>
                </table>

                <div className="notes-section">
                      <h4>Notes:</h4>
                        <li>&bull; Returns are assumed to be generated linearly through the year.</li>
                        <li>&bull; Other Expense includes Account Opening charges, stamp duty /Audit Fee/ Bank charges / Fund Accounting charges / Custody Fee / demat charges or other miscellaneous expense.</li>
                        <li>&bull; Brokerage and transaction cost for the illustration purpose is charged on the Average AUM. However, Brokerage and Transaction cost are charged on basis the actuals trades. </li>
                        <li>&bull; All Fees and charges are subject to GST at 18% or as per extant regulations</li>
                        <li>&bull; For this illustration, High Water Mark for the 1st Year is the Capital invested and from second year onwards it will be higher of Hurdle Value or Net Portfolio Value post charging all fees and expenses. </li>
                        <li>&bull; Hurdle computation is prorated in case the performance fee period is less than 1 year OR if there are inflow/outflows from the portfolio.</li>
                        <li>&bull; All Amounts are in INR.</li>
                    </div>
                    <div className="Disclaimer">
                        <h6 style={{  marginTop: "3px" }}>Disclaimer:</h6>
                        <p style={{ fontStyle: "italic", fontWeight: "normal", fontSize: "12px", lineHeight: "1.5", marginTop: "1px" }}>
                        This document has been prepared by Agreya Capital Advisors LLP (“Agreya”), a SEBI registered Portfolio Manager (SEBI Registration Number INP000006077) for information purposes only. No liability is accepted by Agreya or its representative whatsoever for any direct, indirect or consequential loss arising from the use of this document. This document is not intended as an offer or publication or solicitation for distribution or for subscription of or purchase or sale of any securities or financial instruments(s) to anyone. The information contained in this document shall not be published, reproduced or disseminated without specific confirmation from Agreya. Any person/s accessing data contained in this presentation, hereby confirms that they are not a U.S. person, within the definition of the term "US Person" under the US Securities laws.
                      </p>

                      </div>
                  </div>
                )}
{/* Multi-Year fixed Fee Model Scenario Table */}
{selectedFeeModel === "MultiYearFixed"  && selectedApproach !== "Portfolio Return Optimiser" && scenarios.length > 0 && (
                  <div id="pdf-content" className="desktop-content">
                    <div className="header-container">
                      <div className="logo-container">
                        <img src={logo} alt="Agreya Logo" className="logo" />
                      </div>
                      <h2 className="table-h">FIXED FEE MODEL - {selectedApproach}</h2>
                    </div> 
                    <table className="assumptions-table">
                <thead>
                  <tr>
                    <th colSpan={3}>Assumptions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Capital Contribution (Rs.)</td>
                    <td>a</td>
                    <td>{formatNumber(assumptions.a)}</td>
                  </tr>
                  <tr>
                    <td>Management Fee (%age per annum)</td>
                    <td>b</td>
                    <td>{assumptions.b}%</td>
                  </tr>
                  <tr>
                    <td>Other Expenses (% pa including GST)</td>
                    <td>c</td>
                    <td>{assumptions.c}%</td>
                  </tr>
                  <tr>
                    <td>Brokerage and Transaction cost (% pa including GST)</td>
                    <td>d</td>
                    <td>{assumptions.d}%</td>
                  </tr>
                </tbody>
                
              </table>
                  
                  <table className="scenario-table">
                  <thead>
                  <tr>
                    <th>Fee Illustration</th>
                    <th>Variable</th>
                    <th>Formula</th>
                    <th>Yr 1<br/>Loss of 26%</th>
                    <th>Yr 2<br/>Gain of 79%</th>
                    <th>Yr 3<br/>Gain of 22%</th>
                    <th>Yr 4<br/>Nil Gain/Loss</th>
                    <th>Yr 5<br/>Gain of 40%</th>
                  </tr>
                </thead>

                  <tbody>
                    <tr>
                      <td>Capital Contributed /Assets under Management</td>
                      <td>i</td>
                      <td>i=a (for 1st year);<br/> i=xvi(from 2nd year onwards)</td>
                      {scenarios.map((scenario, index) => (
                        <td key={`value-i-${index}`}>{formatNumber(index === 0 ? assumptions.a : scenarios[index - 1].netValueAfterFees)}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>Gain / (Loss) on Investment based on the Scenario </td>
                      <td>ii</td>
                      <td>ii = i * Scenario</td>
                      {scenarios.map((scenario, index) => (
                        <td key={`value-ii-${index}`}>{formatNumber(scenario.gainOrLoss)}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>Gross Value of the Portfolio at the end of the year </td>
                      <td>iii</td>
                      <td>iii = i + ii</td>
                      {scenarios.map((scenario, index) => (
                        <td key={`value-iii-${index}`}>{formatNumber(scenario.grossValue)}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>Daily Weighted Average assets under management </td>
                      <td>iv</td>
                      <td>iv = (i + iii) / 2</td>
                      {scenarios.map((scenario, index) => (
                        <td key={`value-iv-${index}`}>{formatNumber(scenario.averageAUM)}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>Other Expense</td>
                      <td>v</td>
                      <td>v = iv * c</td>
                      {scenarios.map((scenario, index) => (
                        <td key={`value-v-${index}`}>{formatNumber(scenario.otherExpense)}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>Brokerage and Transaction cost</td>
                      <td>vi</td>
                      <td>vi = iv * f</td>
                      {scenarios.map((scenario, index) => (
                        <td key={`value-vi-${index}`}>{formatNumber(scenario.brokerageCost)}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>Management Fees</td>
                      <td>vii</td>
                      <td>vii = (iv + v + vi) * b</td>
                      {scenarios.map((scenario, index) => (
                        <td key={`value-vii-${index}`}>{formatNumber(scenario.managementFees)}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>GST on Management Fees</td>
                      <td>viii</td>
                      <td>viii = vii * 18%</td>
                      {scenarios.map((scenario, index) => (
                        <td key={`value-viii-${index}`}>{formatNumber(scenario.gstOnManagementFee)}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>Total charges before Performance fee</td>
                      <td>ix</td>
                      <td>ix = v + vi + vii + viii</td>
                      {scenarios.map((scenario, index) => (
                        <td key={`value-ix-${index}`}>{formatNumber(scenario.totalCharges)}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>Net value of the Portfolio at the end of the year after all fees and expenses</td>
                      <td>x</td>
                      <td>x = iii+ ix</td>
                      {scenarios.map((scenario, index) => (
                        <td key={`value-xvi-${index}`}>{formatNumber(scenario.netValueAfterFees)}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>% Portfolio Return  for the year</td>
                      <td>xi</td>
                      <td>xi= ((x - i) / i) %</td>
                      {scenarios.map((scenario, index) => (
                        <td key={`value-xvii-${index}`}>{scenario.portfolioReturn !== undefined ? scenario.portfolioReturn.toFixed(2) : 'N/A'}%</td>
                      ))}
                    </tr>
          
                  </tbody>
                </table>

                <div className="notes-section">
                      <h4>Notes:</h4>
                        <li>&bull; Returns are assumed to be generated linearly through the year.</li>
                        <li>&bull; Other Expense includes Account Opening charges, stamp duty /Audit Fee/ Bank charges / Fund Accounting charges / Custody Fee / demat charges or other miscellaneous expense.</li>
                        <li>&bull; Brokerage and transaction cost for the illustration purpose is charged on the Average AUM. However, Brokerage and Transaction cost are charged on basis the actuals trades. </li>
                        <li>&bull; All Fees and charges are subject to GST at 18% or as per extant regulations</li>
                        <li>&bull; All Amounts are in INR.</li>
                    </div>
                    <div className="Disclaimer">
  <h6 style={{  marginTop: "3px" }}>Disclaimer:</h6>
  <p style={{ fontStyle: "italic", fontWeight: "normal", fontSize: "12px", lineHeight: "1.5", marginTop: "1px" }}>
  This document has been prepared by Agreya Capital Advisors LLP (“Agreya”), a SEBI registered Portfolio Manager (SEBI Registration Number INP000006077) for information purposes only. No liability is accepted by Agreya or its representative whatsoever for any direct, indirect or consequential loss arising from the use of this document. This document is not intended as an offer or publication or solicitation for distribution or for subscription of or purchase or sale of any securities or financial instruments(s) to anyone. The information contained in this document shall not be published, reproduced or disseminated without specific confirmation from Agreya. Any person/s accessing data contained in this presentation, hereby confirms that they are not a U.S. person, within the definition of the term "US Person" under the US Securities laws.
</p>

</div>
                  </div>
                )}
                {/* Multi-Year Hybrid Portfolio Fee Model Scenario Table */}
              {selectedFeeModel === "MultiYearHybrid" && selectedApproach === "Portfolio Return Optimiser" && scenarios.length > 0 && (
                  <div id="pdf-content" className="desktop-content">
                 <div className="header-container">
                    <div className="logo-container">
                      <img src={logo} alt="Agreya Logo" className="logo" />
                    </div>
                    <h2 className="table-h">HYBRID FEE MODEL - {selectedApproach}</h2>
                  </div> 
                    <table className="assumptions-table">
                <thead>
                  <tr>
                    <th colSpan={3}>Assumptions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Capital Contribution</td>
                    <td>a</td>
                    <td>{formatNumber(assumptions.a)}</td>
                  </tr>
                  <tr>
                    <td>Cash Component out of Total Capital</td>
                    <td>b</td>
                    <td>{formatNumber(100000)}</td>
                  </tr>
                  <tr>
                    <td>Management Fee (%age per annum)</td>
                    <td>c</td>
                    <td>{assumptions.b}%</td>
                  </tr>
                  <tr>
                    <td>Other Expenses (% pa including GST)</td>
                    <td>d</td>
                    <td>{assumptions.c}%</td>
                  </tr>
                  <tr>
                    <td>Brokerage and Transaction cost (% pa including GST)</td>
                    <td>e</td>
                    <td>{assumptions.d}%</td>
                  </tr>
                  <tr>
                    <td>Performance Fee(%age per annum)</td>
                    <td>f</td>
                    <td>{assumptions.e}%</td>
                  </tr>
                </tbody>
              </table>
          <table className="scenario-table">
          <thead>
                  <tr>
                    <th>Fee Illustration</th>
                    <th>Variable</th>
                    <th>Formula</th>
                    <th>Yr 1<br/>Loss of Rs. 260,000</th>
                    <th>Yr 2<br/>Gain of Rs. 790,000</th>
                    <th>Yr 3<br/>Gain of Rs. 220,000</th>
                    <th>Yr 4<br/>Nil Gain/Loss</th>
                    <th>Yr 5<br/>Gain of Rs. 400,000</th>
                  </tr>
                </thead>
            <tbody>
              {[
                { label: 'Capital Contributed / Assets under Management', key: 'i', formula: 'i = a (for 1st year); i = xvii (from 2nd year onwards)' },
                { label: 'Derivative Income/All Income during the year', key: 'ii', formula: 'ii = Gain / (Loss)' },
                { label: 'Gross Value of the Portfolio at the end of the year', key: 'iii', formula: 'iii = i + ii' },
                { label: 'Daily Weighted Average assets under management', key: 'iv', formula: 'iv = (i + iii) / 2' },
                { label: 'Other Expense', key: 'v', formula: 'v = iv * d' },
                { label: 'Brokerage and Transaction cost', key: 'vi', formula: 'vi = iv * e' },
                { label: 'Management Fees', key: 'vii', formula: 'vii = (iv + v + vi) * c' },
                { label: 'GST on Management Fees', key: 'viii', formula: 'viii = vii * 18%' },
                { label: 'Total charges excluding performance fee', key: 'ix', formula: 'ix = v + vi + vii + viii' },
                { label: 'Gross Value of the Portfolio before Performance fee', key: 'x', formula: 'x = iii + ix' },
                { label: 'Performance Fee Applicability', key: 'xi', formula: 'If(ii+vii) > 0, then Yes else No' },
                { label: 'Portfolio return subject to Performance Fee', key: 'xii', formula: 'If xi=Yes, then (ii+vii) else 0' },
                { label: 'Previous year loss carried forward if any', key: 'xiii', formula: 'If xviii < 0, then xviii, else 0' },
                { label: 'Net Return subject to Performance Fee', key: 'xiv', formula: 'xiv = xii + xiii' },
                { label: 'Performance Fee', key: 'xv', formula: 'xv = xiv * f' },
                { label: 'GST on Performance Fee (Rs.)', key: 'xvi', formula: 'xvi = xv * 18%' },
                { label: 'Net value of the Portfolio at the end of the year after all fees and expenses', key: 'xvii', formula: 'xvii = x + xv + xvi' },
                { label: 'Loss carried forward if any', key: 'xviii', formula: 'If ii < 0, then ii, else 0' },
              ].map((row, rowIndex) => (
                <tr key={`row-${rowIndex}`}>
                  <td>{row.label}</td>
                  <td>{row.key}</td>
                  <td>{row.formula}</td>
                  {scenarios.map((scenario, index) => (
                    <td key={`value-${row.key}-${index}`}>{formatNumber(scenario[row.key])}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <div className="notes-section">
                <h5>Notes:</h5>
                  <li>&bull; Other Expense includes Account Opening charges, stamp duty /Audit Fee/ Bank charges / Fund Accounting charges / Custody Fee / demat charges or other miscellaneous expense</li>
                  <li>&bull; Brokerage and transaction cost for the illustration purpose is charged on the Average AUM. However, Brokerage and Transaction cost are charged on basis the actuals trades. </li>
                  <li>&bull; All Fees and charges are subject to GST at 18% or as per extant regulations</li>
                  <li>&bull; All Amounts are in INR.</li>
              </div>
              <div className="notes-section">
                <h6>Disclaimer:</h6>
                <p style={{ fontStyle: "italic", fontWeight: "normal", fontSize: "13px", lineHeight: "1.5", marginTop: "1px" }}>
                This document has been prepared by Agreya Capital Advisors LLP (“Agreya”), a SEBI registered Portfolio Manager (SEBI Registration Number INP000006077) for information purposes only. No liability is accepted by Agreya or its representative whatsoever for any direct, indirect or consequential loss arising from the use of this document. This document is not intended as an offer or publication or solicitation for distribution or for subscription of or purchase or sale of any securities or financial instruments(s) to anyone. The information contained in this document shall not be published, reproduced or disseminated without specific confirmation from Agreya. Any person/s accessing data contained in this presentation, hereby confirms that they are not a U.S. person, within the definition of the term "US Person" under the US Securities laws.
              </p>
              </div>
            </div>   
          )}
           {scenarios.length > 0 &&(
       <button onClick={handleBackToSelection} className="back-button1">
      Back to Selection Page
    </button>
      )}
        {scenarios.length > 0 &&(
        <button onClick={downloadPDF} className="download-button">
                    Download as PDF
                  </button>                   
      )}
      </div>
    </div>
    </main>
  );
}
export default FeeSummary;
