import React,{useState,useMemo} from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SectionsTitle from "../common/SectionsTitle";
import { Link } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import a4 from "../../assets/images/ar.png";
import aright from "../../assets/images/aright.png";
import aleft from "../../assets/images/aleft.png";
export default function AboutPgTabs({
  
  secTitle,
  secSubtitle,
  tabsnav,
  tabslist,
})  {
  const [activeTab, setActiveTab] = useState(0);
  const isMobile = useMemo(() => {
    return window.innerWidth <= 768; 
  }, []);

{
  //console.log("tabsnav:", tabsnav);
// console.log("tabslist:", tabslist);
  return (
    <section className="tabs-wrapper">
      <div className="container">
      <div>
      <p className="first-letter" style={{fontSize: "16px",color: "var(--paragraph_color)",fontWeight:"500",marginBottom: "10px",textIndent:"30px", textAlign: "justify",}}>
      Agreya Capital Advisors LLP (Agreya Capital) is a SEBI registered portfolio management service provider offering discretionary, non-discretionary and advisory fund management services. Agreya means ‘first’ or “foremost” in Sanskrit as it aims to become its client’s prominent choice amongst the investment managers.
      </p>
    </div>
    
        <div className="row text-center">
          <div className="col-12">
            <SectionsTitle title={secTitle} subtitle={secSubtitle} />
            
          </div>
        </div>

        <div className="tabs-content-wrap">
          <Tabs>
            <TabList className="tabs-nav">
              {tabsnav.map((item, index) => (
                <Tab
                  key={index}
                  onClick={() => setActiveTab(index)}
                  className={activeTab === index ? "active" : ""}
                >
                  {item.title}
                </Tab>
              ))}
            </TabList>
            {tabslist.map((tab, index) => (
              <TabPanel className="tabs-content" key={index}>
                <div className={"tabs-content-flex " + tab.img_position}>
                  {tab.img ? (
                    <div className="tabs-left">
                      <img src={tab.img} alt="Tabs" />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="tabs-right">
                  {/* {tab.pera3 ? (
                      <p  >
                        {tab.pera3}
                      </p>
                    ) : (
                      ""
                    )} */}
                    {tab.desc ? (
                      <p className="tabs-desc" style={{ paddingTop: "0px",textAlign:isMobile?"justify":null }}>
                        {tab.desc}
                      </p>
                    ) : (
                      ""
                    )}
{/* {tab.ulList1 && activeTab === 1 && (
  <div className="tabs-cards" style={{ display: 'flex', flexWrap: 'wrap' }}>
    {tab.ulList1.map((item, index) => (
      <div key={index} className="card" style={{ width: "calc(50% - 30px)", margin: "0 5px 20px"}}>
        <div className="text-content" style={{ margin: "20px" }}>
          {item.point}
        </div>
      </div>
    ))}
  </div>
)} */}
{tab.ulList1 && activeTab === 1 && (
  <div className="tabs-cards" style={{ display: 'flex', flexWrap: 'wrap' }}>
    {tab.ulList1.map((item, index) => (
      <div key={index} className="card" style={{ width: "calc(50% - 20px)", margin: "0 10px 20px", padding: "10px", boxSizing: "border-box" }}>
        <div className="text-content" style={{ fontSize:isMobile?"10px":null,lineHeight:isMobile?"20px":null,margin: "5px" ,textAlign:"justify",wordBreak:"break-word"}}>
          {item.point}
        </div>
      </div>
    ))}
  </div>
)}



<div className="tabs-cards" style={{ display: 'flex', flexWrap: 'wrap' }}>
  {/* Displaying a card for each point in ulList1 */}
  {tab.ulList1 && activeTab !== 1 && tab.ulList1.map((item, index) => (
    <div key={index} className="card" style={{ width: "calc(50% - 20px)", display: 'flex',height:"10%" }}>
      {item.img && (
        <div className="card-image" style={{display: 'flex'}}>
          <img src={item.img} alt="Card" style={{maxWidth: "80%", maxHeight: "80%,",alignItems:"center"  }} />
        </div>
      )}
      <div className="text-content" style={{fontSize:isMobile?"10px":null,lineHeight:isMobile?"20px":null, Height: "8%",width: "100%", display: 'flex',textAlign:isMobile?'center':"justify",alignItems:"center",wordBreak:"break-word"}}>
        {item.point}
      </div>
    </div>
  ))}
</div>
 {tab.pera && <p className="tabs-desc" style={{textAlign:"center",marginTop:"-10px",textAlign:isMobile?"justify":null}}>{tab.pera}</p>}        
                     
    {/* {tab.pera && <p className="tabs-desc" style={{textAlign:"center",marginTop:"50px"}}>{tab.pera}</p>}
      {tab.ulList2 && (
        <div className="tabs-cards">
          {tab.ulList2.map((i, index) => (
            <div key={index} className="card-container">
              <div className="card" style={{ width: "600px", textAlign: "center", marginLeft: "200px" }}>
                <div className="card-content" style={{ display: "block" }}>
                  
                  {i}
                </div>
              </div>
              {index !== tab.ulList2.length - 1 && (
          <img src={a4} style={{ height: "40px", width: "40px", marginLeft: "450px" }} alt="Down Arrow" className="down-arrow" />
        )}
            </div>
          ))}
        </div>
      )} */}
{tab.ulList2 && (
  <div>
  <div className="tabscards"style={{ display:"center" }}>
    {/* Container for the first 3 cards */}
    <div className="row-container" style={{ display: "flex"}}>
  {tab.ulList2.slice(0, 3).map((i, index) => (
    <React.Fragment key={index}>
      <div className="card-container">
        <div className="card" style={{ width: "330px",height:"70%", textAlign: "center", marginBottom: "20px" }}>
          <div className="card-content" style={{ display: "flex",fontWeight:"bold" }}>
            {i}
          </div>
        </div>
      </div>
      {/* Display custom image between the card containers */}
      {index < 2 && (
        <img src={aright} style={{ height: "20px", width: "20px" ,marginTop: "45px" }} alt="Right Arrow" className="arrow" />
      )}
    </React.Fragment>
  ))}
</div>
<div className="row-container" style={{ display: "flex",justifyContent: "flex-end", marginRight: "15%",marginTop:"-40px"}}>
{index ==0 && (
            <img src={a4} style={{ height: "20px", width: "20px",marginTop: "18px" }} alt="Right Arrow" className="arrow" />
          )}
 </div>
    {/* Container for the next 2 cards */}
    <div className="row-container" style={{ display: "flex",justifyContent: "flex-end", marginRight: "0%"}}>
      {tab.ulList2.slice(3, 5).map((i, index) => (
        <React.Fragment key={index}>
        <div key={index} className="card-container">
          <div className="card" style={{width: "530px",height:"70%", textAlign: "center", marginBottom: "20px"}}>
            <div className="card-content" style={{ display: "flex",fontWeight:"bold"  }}>
              {i}
            </div>
          </div>
        </div>
          {index ==0 && (
            <img src={aleft} style={{ height: "20px", width: "20px",marginTop: "35px" }} alt="Right Arrow" className="arrow" />
          )}
      </React.Fragment>
      ))}
    </div>
    <div className="row-container" style={{ display: "flex",justifyContent: "flex-end", marginRight: "60%",marginTop:"-40px"}}>
{index ==0 && (
            <img src={a4} style={{ height: "20px", width: "20px",marginTop: "18px" }} alt="Right Arrow" className="arrow" />
          )}
 </div>
    {/* Container for the last 2 cards */}
    <div className="row-container" style={{ display: "flex", justifyContent: "flex-end", marginRight: "10%" }}>
  {tab.ulList2.slice(5).map((i, index, array) => (
    <React.Fragment key={index}>
      <div className="card-container">
        <div className="card" style={{ alignItems:"center",textAlignLast: "center", width: "360px", textAlign: "center", marginBottom: "20px", backgroundColor: index === array.length - 1 ? "#C39239" : "transparent", border: index === array.length - 1 ? "2px solid black" : "1px solid transparent", boxShadow: index === array.length - 1 ? "0px 0px 10px rgba(0, 0, 0, 0.5)" : "none",border: "2px solid #ccc"}}>
          <div className="card-content" style={{ display: "flex", fontWeight: index === array.length - 1 ? "bold" : "normal", fontSize: index === array.length - 1 ? "18px" : "inherit", color: index === array.length - 1 ? "#033132" : "inherit" ,fontWeight:"bold" }}>
            {i}
          </div>
        </div>
      </div>
      {index === 0 && (
        <img src={aright} style={{ height: "20px", width: "20px", marginTop: "35px" }} alt="Right Arrow" className="arrow" />
      )}
    </React.Fragment>
  ))}
</div>
</div>
   <div className="tabscards-mobile" style={{ display: "center" }}>
    {tab.ulList2.map((item, index) => (
      <React.Fragment key={index}>
        <div className="card-container">
          <div className="card" style={{ width: "330px", height: "20%", textAlign: "center" }}>
            <div className="card-content" style={{ display: "flex", fontWeight: "bold" }}>
              {item}
            </div>
          </div>
        </div>
        {/* Display custom image between the card containers except for the last one */}
        {index < tab.ulList2.length - 1 && (
          <img src={a4} style={{ height: "20px", width: "20px" }} alt="Right Arrow" className="arrow" />
        )}
      </React.Fragment>
    ))}
      </div>
  </div>
)}
                      {tab.pera2 ? (
                        <p className="tabs-desc"style={{margin:isMobile?"0px":"30px",textAlign:isMobile?"justify":null}}>{tab.pera2}</p>
                        
                      ) : (
                        ""
                      )}
  


                      {/* {tab.ulList1 ? (
                        <p className="tabs-desc">
                          {tab.ulList1.map((i, index) => {
                            return <li key={index}>{i}</li>;
                          })}
                        </p>
                      ) : (
                        ""
                      )} */}
                         </div>
                </div>
              </TabPanel>
            ))}
          </Tabs>
        </div>
      </div>
    </section>
  );
}
}