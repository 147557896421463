import React, { useState } from "react";
import { FiChevronRight } from "react-icons/fi";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressBook } from '@fortawesome/free-solid-svg-icons';
import { faListCheck } from '@fortawesome/free-solid-svg-icons'
const GetInTouchButton = () => {
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    city: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const response = await fetch(
          "https://xyl9r0rd2l.execute-api.us-east-2.amazonaws.com/prod/agreya-website-form",
          {
            method: "POST",
            body: JSON.stringify({
              name: formData.name,
              email: formData.email,
              phone_number: formData.phone,
              city: formData.city,
            }),
          }
        );
  
        if (!response.ok) {
          throw new Error("Failed to submit form");
        }
  
        setFormData({
          name: "",
          phone: "",
          email: "",
          city: "",
        });
        setShowForm(false);
        toast.success("Form submitted successfully!");
      } catch (error) {
        console.error("Error:", error);
        setShowForm(false);
        toast.success("Form submitted successfully!");
      }

  };

  return (

    <div>
          <ToastContainer />
          <div className="get-in-touch-container">
  <button
    style={{
      position: 'relative',
      borderRadius: '50%',
      width: '50px',
      height: '50px',
      textAlign: 'center',
      textDecoration: 'none',
      color: '#fff',
      backgroundColor: '#C39239',
      overflow: 'hidden',
      border: 'none',
    }}
    onClick={() => {
      setShowForm(true);
      setFormData({
        name: '',
        phone: '',
        email: '',
        city: '',
      });
    }}
  >
    <FontAwesomeIcon
      // title="Get in Touch"
      icon={faAddressBook}
      style={{ fontSize: '24px' }}
    />
  </button>
  <span className="button-title">Get in Touch</span>
</div>

    
 
<div className="Invest">
  <a
    href="https://stocks.agreyacapital.com/onboarding/new-client/"
    className="invest-link"
    target="_blank"
    rel="noopener noreferrer"
  >
    <FontAwesomeIcon
      icon={faListCheck}
      className="invest-icon"
    />
  </a>
  <span className="invest-title">Invest Directly </span>
</div>

      {/* Modal/Popup Form */}
      {showForm && (
        <div className="modal" style={{ display: showForm ? "block" : "none" }}>
          <div className="modal-content">
            <span className="close" onClick={() => setShowForm(false)}>
              &times;
            </span>
            <h2
              style={{
                textAlign: "center",
                fontWeight: "bolder",
                fontFamily: "Dancing Script",
                color: "#212529",
                marginBottom: "10px",
                fontFamily: "Montserrat, sans-serif",
              }}
            >
              Get in Touch
            </h2>
            <form style={{fontFamily: "Montserrat, sans-serif",}} onSubmit={handleSubmit}>
              <input
                type="text"
                placeholder="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              <input
                type="tel"
                placeholder="Phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                pattern="[0-9]{10}" 
                title="Please enter a 10-digit phone number" 
                required
              />
              <input
                type="email"
                placeholder="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                placeholder="City"
                name="city"
                value={formData.location}
                onChange={handleChange}
                required
              />
              {/* Setting text-align to center */}
              <div style={{ alignItems: "center", marginLeft: "10%" }}>
                <button
                  type="submit"
                  style={{
                    backgroundColor: "#033132",
                    color: "white",
                    padding: "5px",
                    borderRadius: "0.25rem",
                    fontFamily: "Montserrat, sans-serif",
                  }}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default GetInTouchButton;
