import {
  FaDonate,
  FaFacebookF,
  FaGooglePlusG,
  FaHandshake,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import {
  FiBarChart,
  FiBarChart2,
  FiBriefcase,
  FiCheckCircle,
  FiChevronRight,
  FiLock,
  FiMonitor,
  FiTarget,
  FiUserCheck,
} from "react-icons/fi";
import React from "react";

import logo from "../assets/images/logo.png"; // 33x33
import flat1 from "../assets/images/flag.jpg"; // 33x33
import flat2 from "../assets/images/flag2.jpg"; // 33x33
import flat3 from "../assets/images/flag3.jpg"; // 33x33
import herosliderbg from "../assets/images/shop-slider1.jpg"; // 1920x1024
import herosliderbg1 from "../assets/images/home-slider-1.jpg"; // 1920x1024
import herosliderbg2 from "../assets/images/home-slider-2.jpg"; // 1920x1024
import herosliderbg3 from "../assets/images/home-slider-3.jpg"; // 1920x1024
import herosliderbg4 from "../assets/images/home-slider-4.jpg"; // 1920x1024
import herosliderbg5 from "../assets/images/home-slider-5.jpg"; // 1920x1024
import herosliderbg6 from "../assets/images/home-slider-6.jpg"; // 1920x1024
import herosliderbg7 from "../assets/images/home-slider-7.jpg"; // 1920x1024
import herosliderbg8 from "../assets/images/home-slider-8.jpg"; // 1920x1024
import herosliderbg9 from "../assets/images/home-slider-9.jpg"; // 1920x1024
import { MdAccountBalance } from "react-icons/md";
import {
  AiOutlineCheckCircle,
  AiOutlineDollar,
  AiOutlineFileSearch,
  AiOutlineNotification,
  AiOutlineRocket,
  AiOutlineUsergroupAdd,
} from "react-icons/ai";
import {
  IoIosBuild,
  IoIosHammer,
  IoIosNotificationsOutline,
} from "react-icons/io";
import { TiLightbulb } from "react-icons/ti"; // 750x470
import arjun from "../assets/images/arjun.jpg";
import mvk from "../assets/images/mvk.jpg";
import vivek from "../assets/images/vivek.jpg";
import shail from "../assets/images/shail.jpg";

import img1 from "../assets/images/about-img.jpg"; // 458x315
import img2 from "../assets/images/about-img4.jpg"; // 555x354
import img3 from "../assets/images/testi-img1.jpg"; // 100x100
import img4 from "../assets/images/team-1.jpg"; // 360x370
// import img32 from "../assets/images/img32.jpg"; // 360x370
import Anand from "../assets/images/Anand.jpg"; 
import Nilesh from "../assets/images/Nilesh.jpg"; 
import Pankaj from "../assets/images/Pankaj.jpg"; 
import img32 from "../assets/images/img32.jpg"; // 360x370
// import Anand from "../assets/images/Anand.jpg"; 
// import Nilesh from "../assets/images/Nilesh.jpg"; 
// import Pankaj from "../assets/images/Pankaj.jpg"; 
import img5 from "../assets/images/client1.png"; // 110x39
import img6 from "../assets/images/client2.png"; // 160x32
import img7 from "../assets/images/client3.png"; // 156x33
import img8 from "../assets/images/client4.png"; // 137x36
import img9 from "../assets/images/client5.png"; // 161x35
import img10 from "../assets/images/img1.jpg"; // 555x368
import img11 from "../assets/images/img11.jpg"; // 1140x594
import img12 from "../assets/images/team-8.jpg"; // 360x453
import img13 from "../assets/images/img3.jpg"; // 360x240
import img14 from "../assets/images/img15.jpg"; // 1140x608
import slider1 from "../assets/images/img12.jpg"; // 750x469
import img15 from "../assets/images/img22.jpg"; // 350x256
import errorimg from "../assets/images/404-img3.gif"; // 550x476
import chartimg from "../assets/images/chart-bar.png";
import category1 from "../assets/images/shopimg.jpg"; // 570x290
import CountUp from "react-countup";
import footerLogo from "../assets/images/logo2.png";
import { GiCutDiamond } from "react-icons/gi";
import p1 from "../assets/images/p1.png";
import p2 from "../assets/images/p2.png";
import p3 from "../assets/images/p3.png";
import p4 from "../assets/images/p4.png";
import c1 from "../assets/images/c1.png";
import c2 from "../assets/images/c2.png";
import c3 from "../assets/images/c3.png";
import c4 from "../assets/images/c4.png";



const sectiondata = {
  HeaderData: {
    HeaderBar: {
      phoneNumber: "+91-80-41690158",
      email: "info@agreyacapital.com",
      //   location:
      //     "Agreya Capital Advisors LLP 360, 6th Main, 1st Block, Koramangala, Bengaluru - 560 034",
      //   socialLists: [
      //     {
      //       icon: <FaFacebookF />,
      //       url: "https://facebook.com",
      //     },
      //     {
      //       icon: <FaTwitter />,
      //       url: "https://twitter.com",
      //     },
      //     {
      //       icon: <FaYoutube />,
      //       url: "https://youtube.com",
      //     },
      //   ],
      //   languages: [
      //     {
      //       name: "English",
      //       flag: flat1,
      //       url: "#",
      //     },
      //     {
      //       name: "USA",
      //       flag: flat2,
      //       url: "#",
      //     },
      //     {
      //       name: "France",
      //       flag: flat3,
      //       url: "#",
      //     },
      //   ],
      //   btntext: "Work With Us",
      btnicon: <FiChevronRight className="icon" />,
      btnurl: "#",
    },
    HeaderMenuBar: {
      logourl: logo,
      menus: [
        {
          title: "Home",
          path: "/",
        },
        {
          title: "About Us",
          path: "/about",
          //   dropdown: [
          //     {
          //       title: "Values",
          //       path: "/values",
          //     },

          //     {
          //       title: "Philosophy",
          //       path: "/philosophy",
          //     },
          //     {
          //       title: "Clients",
          //       path: "/clients",
          //     },
          //   ],
        },
        
        

        {
          title: "People",
          path: "/people",
          //   dropdown: [
          //     {
          //       title: "Leadership Team",
          //       path: "/leadershipTeam",
          //     },
          //     {
          //       title: "Advisory Team",
          //       path: "/advisoryTeam",
          //       dep_dropdown: [
          //         {
          //           title: "Team Grid",
          //           path: "/team-grid",
          //         },
          //         {
          //           title: "Team Details",
          //           path: "/team-details",
          //         },
          //       ],
          //     },
          //     {
          //       title: "Testimonial",
          //       path: "/testimonial",
          //     },
          //     {
          //       title: "FAQ",
          //       path: "/faq",
          //     },
          //     {
          //       title: "Our Pricing",
          //       path: "/our-pricing",
          //     },
          //     {
          //       title: "404",
          //       path: "/error",
          //     },
          //     {
          //       title: "User",
          //       path: "/sign-up",
          //       dep_dropdown: [
          //         {
          //           title: "Sign Up",
          //           path: "/sign-up",
          //         },
          //         {
          //           title: "Login",
          //           path: "/login",
          //         },
          //         {
          //           title: "Recover Password",
          //           path: "/forgot-password",
          //         },
          //       ],
          //     },
          //   ],
        },
        // {
        //   // title: "Services",
        //   // path: "/ourservices",
        //   // dropdown: [
        //   //   {
        //   //     title: "Leadership Team",
        //   //     path: "/leadershipTeam",
        //   //   },

        //   // ],
        // },
        {
          title: "Investment Approaches",
          path: "/approaches",
          dropdown: [
            {
              title: "Momentum",
              path: "/approaches?name=0",
            },
            {
              title: "Total Return",
              path: "/approaches?name=1",
            },
            {
              title: "Multi-Asset",
              path: "/approaches?name=2",
            },
            {
              title: "Momentum Enhancer",
              path: "/approaches?name=3",
            },
            {
              title: "Total Return Enhancer",
              path: "/approaches?name=4",
            },
            {
              title: "Multi-Asset Enhancer",
              path: "/approaches?name=5",
            },
            {
              title: "Portfolio Return Optimiser",
              path: "/approaches?name=6",
            },
          ],
        },
        {
          title: "Fee Model",
          path: "/fee-illustration",
        },

        // {
        //   title: "Performance",
        //   path: "/performance",
        // },
        // {
        //   title: "Invest directly with us",
        //   link: "https://zfrmz.com/ZOrwDPnVb3mRw67j01OX",
        // },
        {
          title: "Invest Directly",
          link: "https://stocks.agreyacapital.com/onboarding/new-client/",
        },
        {
          title: "Disclosures",
          path: "#",
          dropdown: [
            {
              title: "Latest Disclosure Document",
              link: "/files/Disclosure_Document.pdf",
            },
            {
              title: "Our Investor Charter",
              link:"/files/Agreya Investor charter.pdf",
            },
            {
              title: "Data on complaints",
              link:"/files/data-on-complaints.pdf",
            },
          ]
        },
        // {
        //   title: "Invest directly with us",
        //   path: ,
        // },
        {
          title: "Login",
          path: "/",
          dropdown: [
            {
              title: "ICICI Bank Custody",
              link: "https://onlinefa.icicibank.com/wealthspectrum/app/login",
            },
            {
              title: "Nuvama Custody",
              link: "https://eclientreporting.nuvamaassetservices.com/wealthspectrum/app/loginWith",
            },
          ],
        },
        {
          title: "Contact",
          path: "/contact",
        },
      ],
    },
    
  },
  Sliders: {
    heroSlider1: [
      {
        title: "Absence of evidence is not evidence of absence",
        description: "– Author Unknown",
        button1: "Get Started",
        button2: "Learn More",
        button1url: "#",
        button2url: "#",
        bg: herosliderbg1,
        col: "8",
      },
      {
        title: "I don’t predict a non-existing future",
        description: "– Ed Seykota",
        button1: "Let's Start Now",
        button2: "Learn More",
        button1url: "#",
        button2url: "#",
        bg: herosliderbg2,
        col: "8 ",
      },
      {
        title: "To win without risk is to triumph without glory",
        description: "– Pierre Corneille",
        button1: "Let's Start Now",
        button2: "Learn More",
        button1url: "#",
        button2url: "#",
        bg: herosliderbg3,
        col: "8",
      },
      {
        title: "Strong opinions, weakly held",
        description: "– Jack Shwager",
        button1: "Let's Start Now",
        button2: "Learn More",
        button1url: "#",
        button2url: "#",
        bg: herosliderbg4,
        col: "8",
      },
      {
        title:
          "You must have a willingness to lose, but a strong desire to win",
        description: "– Gary Biefeldt",
        button1: "Let's Start Now",
        button2: "Learn More",
        button1url: "#",
        button2url: "#",
        bg: herosliderbg5,
        col: "8",
      },
      {
        title:
          "Winning at money is 80 percent behaviour and 20 percent knowledge",
        description: "– Dave Ramsey",
        button1: "Let's Start Now",
        button2: "Learn More",
        button1url: "#",
        button2url: "#",
        bg: herosliderbg6,
        col: "8",
      },
      {
        title:
          "Never stray from the basics of rule number one of always going in with a plan",
        description: "– Mark Minervini",
        button1: "Let's Start Now",
        button2: "Learn More",
        button1url: "#",
        button2url: "#",
        bg: herosliderbg7,
        col: "8",
      },
      // {
      //   title:
      //     "Never stray from the basics of rule number one of always going in with a plan.",
      //   description: "– Mark Minervini",
      //   button1: "Let's Start Now",
      //   button2: "Learn More",
      //   button1url: "#",
      //   button2url: "#",
      //   bg: herosliderbg8,
      //   col: "8",
      // },
      // {
      //   title:
      //     "Never stray from the basics of rule number one of always going in with a plan.",
      //   description: "– Mark Minervini",
      //   button1: "Let's Start Now",
      //   button2: "Learn More",
      //   button1url: "#",
      //   button2url: "#",
      //   bg: herosliderbg9,
      //   col: "8",
      // },
    ],
    testimonial: [
      {
        name: "Tanbir_Ahmed",
        city: "United States",
        img: img1,
        desc: "Enjoy the diversity of ThePin. Check this numerous demos, made for different purposes. Easy to import & highly customizable. All of the presented elements, layouts & styles can be theme.",
      },
      {
        name: "Mark_Doe",
        city: "United States",
        img: img1,
        desc: "Enjoy the diversity of ThePin. Check this numerous demos, made for different purposes. Easy to import & highly customizable. All of the presented elements, layouts & styles can be theme.",
      },
      {
        name: "Rizon_Pet",
        city: "United States",
        img: img1,
        desc: "Enjoy the diversity of ThePin. Check this numerous demos, made for different purposes. Easy to import & highly customizable. All of the presented elements, layouts & styles can be theme.",
      },
    ],
    clientlogos: [
      {
        img: img5,
      },
      {
        img: img6,
      },
      {
        img: img7,
      },
      {
        img: img8,
      },
      {
        img: img9,
      },
    ],
    heroSlider2: [
      {
        title: "Minzel Gives You Power to Grow Your Business.",
        description:
          "Sed ut perspiciatis unde omnis iste natus error sit voluptatem veritatis et quasi architecto beatae vitae dicta.",
        button1: "Get Started",
        button2: "Learn More",
        button1url: "/",
        button2url: "/",
        bg: herosliderbg,
        col: "8",
      },
      {
        title: "Your Business Success is Our Success.",
        description:
          "Sed ut perspiciatis unde omnis iste natus error sit voluptatem veritatis et quasi architecto beatae vitae dicta.",
        button1: "Let's Start Now",
        button2: "Learn More",
        button1url: "/",
        button2url: "/",
        bg: herosliderbg,
        col: "8 offset-2 text-center",
      },
      {
        title: "Work With World Class Finance Experts.",
        description:
          "Sed ut perspiciatis unde omnis iste natus error sit voluptatem veritatis et quasi architecto beatae vitae dicta.",
        button1: "Let's Start Now",
        button2: "Learn More",
        button1url: "/",
        button2url: "/",
        bg: herosliderbg,
        col: "8",
      },
    ],
    shopheroslider: [
      {
        title: "Hand Picked Items Sold Exclusively Online.",
        description:
          "Deserunt dolore voluptatem assumenda quae possimus sunt dignissimos tempora officia. Lorem ipsum dolor sit amet",
        button1: "Shop Now",
        button2: "All Products",
        button1url: "/shop",
        button2url: "/shop",
        bg: herosliderbg,
        col: "8",
      },
      {
        title: "Find That Perfect Gift and Surprise Someone.",
        description:
          "Deserunt dolore voluptatem assumenda quae possimus sunt dignissimos tempora officia. Lorem ipsum dolor sit amet",
        button1: "Shop Now",
        button2: "All Products",
        button1url: "/shop",
        button2url: "/shop",
        bg: herosliderbg,
        col: "8 offset-2 text-center",
      },
      {
        title: "Beautifully Design Pieces by  Talented Designers..",
        description:
          "Deserunt dolore voluptatem assumenda quae possimus sunt dignissimos tempora officia. Lorem ipsum dolor sit amet",
        button1: "Shop Now",
        button2: "All Products",
        button1url: "/shop",
        button2url: "/shop",
        bg: herosliderbg,
        col: "8",
      },
    ],
  },
  Services: {
    Service1: {
      secTitle: "We Offer Marketing and Consulting Services for Businesses.",
      secSubtitle: "Our Dedicated Services",
      secButton: "View All Services",
      secButtonUrl: "/",
      serviceLists: [
        {
          icon: <FiTarget className="service-items-icon" />,
          title: "Finance Management.",
          titleurl: "/service-details",
          description:
            "sed quia lipsum dolor sit atur adipiscing elit is nunc quis tellus sed ligula porta ultricies quis nec neulla.",
          showbtn: false,
          buttontext: "Learn More",
          buttonurl: "/service-details",
        },
        {
          icon: <MdAccountBalance className="service-items-icon" />,
          title: "Banking Investigation.",
          titleurl: "/service-details",
          description:
            "sed quia lipsum dolor sit atur adipiscing elit is nunc quis tellus sed ligula porta ultricies quis nec neulla.",
          showbtn: false,
          buttontext: "Learn More",
          buttonurl: "/service-details",
        },
        {
          icon: <AiOutlineDollar className="service-items-icon" />,
          title: "Business Insurance.",
          titleurl: "/service-details",
          description:
            "sed quia lipsum dolor sit atur adipiscing elit is nunc quis tellus sed ligula porta ultricies quis nec neulla.",
          showbtn: false,
          buttontext: "Learn More",
          buttonurl: "/service-details",
        },
        {
          icon: <AiOutlineFileSearch className="service-items-icon" />,
          title: "Market Research.",
          titleurl: "/service-details",
          description:
            "sed quia lipsum dolor sit atur adipiscing elit is nunc quis tellus sed ligula porta ultricies quis nec neulla.",
          showbtn: false,
          buttontext: "Learn More",
          buttonurl: "/service-details",
        },
        {
          icon: <IoIosBuild className="service-items-icon" />,
          title: "Strong Business",
          titleurl: "/service-details",
          description:
            "sed quia lipsum dolor sit atur adipiscing elit is nunc quis tellus sed ligula porta ultricies quis nec neulla.",
          showbtn: false,
          buttontext: "Learn More",
          buttonurl: "/service-details",
        },
        {
          icon: <FiMonitor className="service-items-icon" />,
          title: "Large Markets",
          titleurl: "/service-details",
          description:
            "sed quia lipsum dolor sit atur adipiscing elit is nunc quis tellus sed ligula porta ultricies quis nec neulla.",
          showbtn: false,
          buttontext: "Learn More",
          buttonurl: "/service-details",
        },
      ],
    },
    Service2: {
      sectitle: "We Provide Wide Range of Business Services.",
      secsubtitle: "You Deserve Best Services",
      content:
        "Investiga tiones demonstr averunt lectores legere me lius quod ii qua legunt saepius. Claritas est etiam pro cessus dynamicus, qui sequitur mutaety tion em consu etudium awquod he legunt saepius clary tyitas Investig atifonesw. tionem consu etudium.",
      buttontext: "Read More",
      buttonurl: "/service-details",
      lists: [
        {
          icon: <AiOutlineDollar />,
          title: "Consumer Products",
          titleurl: "/service-details",
          desc: "Lorem ipsum dolor sit amet, consectetur adipisicing",
        },
        {
          icon: <FiBriefcase />,
          title: "Adult Marketing",
          titleurl: "/service-details",
          desc: "Lorem ipsum dolor sit amet, consectetur adipisicing",
        },
        {
          icon: <FaDonate />,
          title: "Banking Advising",
          titleurl: "/service-details",
          desc: "Lorem ipsum dolor sit amet, consectetur adipisicing",
        },
        {
          icon: <FiBarChart2 />,
          title: "Business Growth",
          titleurl: "/service-details",
          desc: "Lorem ipsum dolor sit amet, consectetur adipisicing",
        },
      ],
    },
    Service3: [
      {
        icon: <FiTarget className="service-items-icon" />,
        title: "Our Mission.",
        titleurl: "/service-details",
        description:
          "sed quia lipsum dolor sit atur adipiscing elit is nunc quis tellus sed ligula porta ultricies quis nec neulla.",
        showbtn: true,
        buttontext: "Learn More",
        buttonurl: "/service-details",
      },
      {
        icon: <IoIosNotificationsOutline className="service-items-icon" />,
        title: "Our Vision.",
        titleurl: "/service-details",
        description:
          "sed quia lipsum dolor sit atur adipiscing elit is nunc quis tellus sed ligula porta ultricies quis nec neulla.",
        showbtn: true,
        buttontext: "Learn More",
        buttonurl: "/service-details",
      },
      {
        icon: <FaHandshake className="service-items-icon" />,
        title: "Our Approach.",
        titleurl: "/service-details",
        description:
          "sed quia lipsum dolor sit atur adipiscing elit is nunc quis tellus sed ligula porta ultricies quis nec neulla.",
        showbtn: true,
        buttontext: "Learn More",
        buttonurl: "/service-details",
      },
    ],
    serviceVideo: {
      videoId: "t3tsMEhlvwM", // Enter YouTube Video's id here
      videobutton: "Watch",
      img: img11,
    },
    serviceDetails: {
      sliders: [slider1, slider1, slider1],
      title: "International Business Opportunities.",
      content1:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered altera tion in some form, by injected humour, or randomised words which don’t look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn’t anything embarrassing hidden.",
      content2:
        "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.",
      features: [
        {
          title: "Seed do eiusmod tempor incididunt ut",
          icon: <FiCheckCircle className="icon" />,
        },
        {
          title: "Exercitation ullamco laboris nis.",
          icon: <FiCheckCircle className="icon" />,
        },
        {
          title: "Quis nostrud exerc citation.",
          icon: <FiCheckCircle className="icon" />,
        },
        {
          title: "Andomised words which don't look",
          icon: <FiCheckCircle className="icon" />,
        },
        {
          title: "Commodo consequat duis autex.",
          icon: <FiCheckCircle className="icon" />,
        },
        {
          title: "Andomised words which don't look",
          icon: <FiCheckCircle className="icon" />,
        },
        {
          title: "All the Lorem ipsum generators",
          icon: <FiCheckCircle className="icon" />,
        },
      ],
      featuresimg: img13,
      plans: {
        img: img13,
        title: "Planning Strategy",
        content:
          "Adipisicing elit, sed do eiusmod tempor incididunt ul labore et dolore magna aliqua",
        lists: [
          {
            title: "research beyond the business plan",
            icon: <FiCheckCircle className="icon" />,
          },
          {
            title: "marketing options and rates.",
            icon: <FiCheckCircle className="icon" />,
          },
          {
            title: "the ability to turnaround consulting.",
            icon: <FiCheckCircle className="icon" />,
          },
        ],
      },
      project: {
        title: "Project Results.",
        content1:
          "There are many variations of passages of Lorem ipsum available, but the majority have suffered altera tion in some form, by injected,",
        content2:
          "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore. eu fugiat nulla pariatur.",
        content3:
          "Enim minim veniam quis nostrud. exercitation ullamco laboris nisi aliquip",
      },
      sidebar: {
        categories: {
          title: "Categories.",
          lists: [
            {
              title: "Finance Management",
              path: "/service-details",
            },
            {
              title: "Banking Investigation",
              path: "/service-details",
            },
            {
              title: "Business Insurance",
              path: "/service-details",
            },
            {
              title: "Car Insurance",
              path: "/service-details",
            },
            {
              title: "Market Research",
              path: "/service-details",
            },
            {
              title: "Home Insurance",
              path: "/service-details",
            },
            {
              title: "Life Insurance",
              path: "/service-details",
            },
            {
              title: "Health Insurance",
              path: "/service-details",
            },
          ],
        },
        contactinfos: {
          title: "Contact Us.",
          address: "121 King Street, USA, New York",
          phonenumber: "+1401574423",
          email: "example@support.com",
        },
        broucher: {
          title: "Brochures",
          content:
            "View our 2020 financial prospectus brochure for an easy to read guide on all of the services offer.",
          button: "Company Presentation",
          buttonurl: "#", // Enter download file url here
        },
      },
    },
  },
  Aboutdata: {
    about1: {
      title: "Welcome to Awards Winning Digital Marketing Agency.",
      subtitle: "Learn About Us",
      content:
        "Agreya Capital is a SEBI registered portfolio management service provider offering discretionary, non-discretionary and advisory fund management services. Agreya means ‘first’ or “foremost” in Sanskrit as it aims to become its client’s prominent choice amongst the investment managers. The 4 co-founders have varied experience across equity, debt, structured debt, corporate & investment banking, foreign exchange, treasury, global markets, family office, trade finance and operations. This diversity is at the heart of Agreya’s fund management approach that draws inspiration from fundamental research, asset price movements and common sense in equal measure.",
      //   readmorebtn: "Read More",
      //   readmorebtnurl: "/about",
      supports: [
        {
          icon: <FiUserCheck />,
          title: "24/7 full Online Support",
        },
        {
          icon: <FiTarget />,
          title: "10 Years of Experience",
        },
        {
          icon: <TiLightbulb />,
          title: "Creative Finance Idea",
        },
      ],
      images: [img1, img1, img1],
      videobtn: "Play",
      videoId: "t3tsMEhlvwM", // Enter YouTube video's id here
      skills: [
        {
          title: "Finance.",
          percent: "90",
        },
        {
          title: "Business.",
          percent: "85",
        },
        {
          title: "Investment.",
          percent: "80",
        },
      ],
    },
    about2: {
      left: {
        title: "We Allow You to Manage & Grow Your Business.",
        content1:
          "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
        content2:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium assumenda distinctio eligendi eum molestiae odit ullam?",
        lists: [
          "Lorem ipsum dolor sit amet.",
          "Lorem ipsum dolor sit amet.",
          "Lorem ipsum dolor sit amet.",
        ],
        buttontext: "Get Started",
        buttonurl: "/",
      },
      right: {
        img1: img10,
        img2: img10,
        title: "We Are Trusted by More Than 19,700 Clients",
        content:
          "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour",
        buttontext: "Join Now",
        buttonurl: "/",
      },
    },
  },
  portfoliodata: {
    img: img14,
    client: "TechyDevs Ltd.",
    date: "February 20, 2020",
    services: [
      {
        title: "Business",
        path: "/",
      },
      {
        title: "Consulting",
        path: "/",
      },
    ],
    website: "www.techydevs.com",
    title: "Business Management.",
    content1:
      "Dut perspiciatis unde omnis iste natus error sit voluptatems accusantium doloremqu laudantiums ut, totams se aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae duis autems vell eums iriure dolors in hendrerit saep. HUGE bump in visitor-to-leads conversion. Just moving our same opt-in offer from the sidebar to the header I went from 2 percent of visitors becoming subscribers to 5 percent.",
    content2:
      "Eveniet in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at seds eros sed et accumsan et iusto odio dignissim. Temporibus autem quibusdam et aut officiis. Optimizing this above-the-fold area to make the most of the screen real estate had the biggest impact of all the things I tested. Switching my opt-in box from the sidebar on the right to the header area just below my logo and navigation was a",
    content3:
      "Eveniet in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at seds eros sed et accumsan et iusto odio dignissim. Temporibus autem quibusdam et aut officiis. Optimizing this above-the-fold area to make the most of the screen real estate had the biggest impact of all the things I tested. Switching my opt-in box from the sidebar on the right to the header area just below my logo and navigation was a",
    projectfeature: {
      title: "Project Features",
      lists: [
        "Strategy and Optimisation consulting",
        "Technologies And Innovations consultant",
        "Financial Planning consultant",
        "Marketing business plan",
        "Advice business plan",
        "Strategy business plan",
        "Analize business plant",
        "Consulting business plan",
      ],
    },
    solutions: {
      title: "Solutions",
      content:
        "Adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      lists: [
        "Strategy And Optimization consulting",
        "Technologies And Innovations consultant",
        "Financial Planning consultant",
        "Secured Transactions consultant",
      ],
    },
    bestresult: {
      title: "Better Results",
      img: chartimg,
    },
    relatedproject: {
      title: "Related Projects.",
      lists: [
        {
          img: img15,
          title: "Business Management",
          path: "/portfolio-details",
          cat: "Business",
        },
        {
          img: img15,
          title: "Digital Analysis",
          path: "/portfolio-details",
          cat: "Business",
        },
        {
          img: img15,
          title: "Fund Management",
          path: "/portfolio-details",
          cat: "Finance",
        },
      ],
    },
  },

  ourServices: [
    {
      title: "Discretionary PMS",
      content:
        "Discretionary PMS is in the nature of investment management, and includes the responsibility of managing, renewing and reshuffling the Portfolio, buying and selling the Securities (as permitted under the Regulations), keeping safe custody of the said Securities and monitoring book closures, dividend, bonus, rights etc. so that all benefits accrue to the Client Portfolio, for an agreed fee structure and for a definite period as described. Under these services, the choice as well as the timings of the investment decisions rest solely with the Portfolio Manager and the Portfolio Manager can exercise any degree of discretion in the investments or management of Client Portfolio. The Portfolio Manager shall be acting in a fiduciary capacity, both, as an agent as well as a trustee, with regard to the Client's account. Under Discretionary PMS, Agreya currently offers 6 investment approaches. ",
    },
    {
      title: "Non-Discretionary PMS",
      content:
        "Under the Non - Discretionary Portfolio Management Services, the Client Portfolio shall be managed in consultation with the Client. Under this service, the Client Portfolio will be managed as per express prior instructions issued by the Client from time to time. The Client will have complete discretion to decide on the investment (instrument, quantity and price or amount). The decision of the Client in deployment of funds and/ or Securities and the handling of his/ her/ its Portfolio is absolute and final. The role of the Portfolio Manager apart from adhering to investments or divestments upon instructions of the Client is restricted to providing market intelligence, research reports, investment strategies, investment statistics and such other material which will enable the Client to take appropriate investment decisions.",
    },
    {
      title: "Investment Advisory Services",
      content:
        "Under the investment advisory services, investment advice is provided to Clients on a non-binding basis. Client may or may not adhere to the advice provided by the Portfolio Manager. All activities pertaining to the execution of transactions, settlement and their reporting are solely the responsibility of the Client. The Portfolio Manager shall only provide advisory services in accordance with the Regulations.",
    },
  ],
  Tabdata: {
    first:"Agreya Capital is a SEBI registered portfolio management service provider offering discretionary, non-discretionary and advisory fund management services. Agreya means ‘first’ or “foremost” in Sanskrit as it aims to become its client’s prominent choice amongst the investment managers",
    AboutPG: {
      secTitle: "Why Choose Us?",
      // secSubtitle: "Our Best Features",
      
      tabsnav: [
        {
          title: "Philosophy",
        },
        {
          title: "Values",
        },
        {
          title: "Clients",
        },
      ],

      items: [
        {
          pera3:"Agreya Capital is a SEBI registered portfolio management service provider offering discretionary, non-discretionary and advisory fund management services. Agreya means ‘first’ or “foremost” in Sanskrit as it aims to become its client’s prominent choice amongst the investment managers",
          desc: "Agreya’s philosophical approach to investment management is deep rooted in the understanding of ‘Risk’ and ‘Returns’ in the market. The strong belief is that there are no absolutes, and every investment approach is relative to, and a function of, the risk-reward metric that it operates on. Over the course of time, the various investment approaches of Agreya Capital have been developed in a systematic way by following a set of founding principles as enumerated below:",
          // ulList1: [
          //   " Sustainability of the approach measured by repeatability of the investment process.",
          //   " Wealth protection measured by drawdown of portfolio compared to the markets.",
          //   " Balance between prudence and risk taking measured by relative performances of the investment approaches against the respective benchmarks.",
          //   " Risk management and mitigation of the portfolio measured by diversification into complementary investment approaches and centred by asset allocation philosophy.",
          // ],
          ulList1:[
            {
              img: p1,
              point: "Sustainability of the approach measured by repeatability of the investment process.",
            },
            {
              img: p2,
              point: " Wealth protection measured by drawdown of portfolio compared to the markets.",
            },
            {
              img: p3,
              point:  " Balance between prudence and risk taking measured by relative performances of the investment approaches against the respective benchmarks.",
            },
            {
              img: p4,
              point:  " Risk management and mitigation of the portfolio measured by diversification into complementary investment approaches and centred by asset allocation philosophy.",
            },
          ],
          pera: "Based on the above principles, the philosophical approach of Agreya follows a process of:",
          ulList2: [
            "Constructing an investment framework and developing an ‘edge’",
            "Understanding the risks associated in the framework ",
            "Analysis and dissection of the investment thesis basis deep research",
            "Back testing and developing a stress model to measure sustainability",
            "Live testing of the investment thesis using proprietary capital ",
            "Review and remodel, if required",
            "Launch of an investment approach",
          ],
          pera2:
            "Every investment approach of Agreya must go through the rigor of being based on the founding principles and the philosophical commitment of ‘our’ capital being deployed first before investing client monies.",
        },
        {
          desc: "The formative influences and learnings of the co-founders are: ",
          ulList1: [
            {
            point:"A great team with the right value systems can build a great institution. Hailing from a business family that failed in 12 businesses before succeeding in the 13th, I have learnt the virtues of patience and persistence.",
            },
            {
              point: "Understanding how any asset price moves and developing systematic, data driven models has been a lifelong passion. Investing is a combination of economics, behavioral finance, crowd psychology and powerful human emotions of hope, greed and fear.",
            },
            {
              point:"Sitting in the cash counter of a shop that sold pumps, pipes & tools gave an early taste of the rush that one gets in running a business. One-new-diversification-a-year mantra in the family business ensured that I was constantly seeing businesses start, consolidate or close.",
            },
            {
              point: "I always believed that a simple idea executed to perfection has a greater potential of achieving scale and success than a brilliant complex idea that is not executed well.",
            },
          ],
            
           pera: "The above learnings have meta-morphed into Agreya’s value systems of product innovation, client centricity and having skin-in-the-game. Hence, the majority of the networth of the firm and its founders is invested in the same investment approaches as that of its clients and this is a continuing commitment to all clients. Agreya endeavours to build a conflict-free and values driven organisation that has 'simplicity' at the core of its investment philosophy.  ",
            
        
        },
        {
          desc: "Client selection and onboarding is a critical aspect of the investment journey with Agreya Capital. While all our clients have a unique combination of risk appetite and return expectation, the broad metrics with which we onboard our clients include the presence of following commonalities:",
          // ulList1: [
          //   "Have wealth creation as primary driver: The focus of our clients is long-term wealth creation and believe that equities have historically been the best investment class to achieve this objective.",
          //   "Define long-term as 3-5 years: Our clients understand that investments in equity markets are long-term investments and cannot be assessed on year-on-year basis.",
          //   "Compare us with benchmark & not against other houses: Every fund house has investment approach with inherent risks and rewards. So, our clients compare us only against benchmark indices over up & down investment cycle.",
          //   "Understand that equity markets have inherent capital erosion risks: Equity/stock market investments do not provide capital protection. Agreya’s clients are cognizant that principal erosion of their investment is possible.",
          // ],
          ulList1:[
            {
              img: c1,
              point: "Have wealth creation as primary driver: The focus of our clients is long-term wealth creation and believe that equities have historically been the best investment class to achieve this objective.",
            },
            {
              img: c2,
              point: "Define long-term as 3-5 years: Our clients understand that investments in equity markets are long-term investments and cannot be assessed on year-on-year basis.",
            },
            {
              img: c3,
              point: "Compare us with benchmark & not against other houses: Every fund house has investment approach with inherent risks and rewards. So, our clients compare us only against benchmark indices over up & down investment cycle.",
            },
            {
              img: c4,
              point:  "Understand that equity markets have inherent capital erosion risks: Equity/stock market investments do not provide capital protection. Agreya’s clients are cognizant that principal erosion of their investment is possible.",
            },
          ],
          // pera: "At Agreya, the constant endeavour is to marry the client expectation with that of its proprietary product offerings. Hence, we are accustomed to and pride ourselves in providing highly customised and unique solutions depending on the risk-reward paradigm of the client. We strongly believe that there are no ‘good’ or ‘not-good’ investment approaches, only whether the investment approach is ‘good for’ or ‘not-good for’ its clients. ",
        },
      ],
    },
    OurServices: {
      secTitle:
        "Agreya Capital offers the following services to all its clients",
      tabsnav: [
        {
          title: "Discretionary PMS",
        },
        {
          title: "Non-Discretionary PMS",
        },
        {
          title: "Investment Advisory Services",
        },
      ],
      items: [
        {
          desc: "Discretionary PMS is in the nature of investment management, and includes the responsibility of managing, renewing and reshuffling the Portfolio, buying and selling the Securities (as permitted under the Regulations), keeping safe custody of the said Securities and monitoring book closures, dividend, bonus, rights etc. so that all benefits accrue to the Client Portfolio, for an agreed fee structure and for a definite period as described. Under these services, the choice as well as the timings of the investment decisions rest solely with the Portfolio Manager and the Portfolio Manager can exercise any degree of discretion in the investments or management of Client Portfolio. The Portfolio Manager shall be acting in a fiduciary capacity, both, as an agent as well as a trustee, with regard to the Client's account. Under Discretionary PMS, Agreya currently offers 6 investment approaches.   ",
        },
        {
          desc: "Under the Non - Discretionary Portfolio Management Services, the Client Portfolio shall be managed in consultation with the Client. Under this service, the Client Portfolio will be managed as per express prior instructions issued by the Client from time to time. The Client will have complete discretion to decide on the investment (instrument, quantity and price or amount). The decision of the Client in deployment of funds and/ or Securities and the handling of his/ her/ its Portfolio is absolute and final. The role of the Portfolio Manager apart from adhering to investments or divestments upon instructions of the Client is restricted to providing market intelligence, research reports, investment strategies, investment statistics and such other material which will enable the Client to take appropriate investment decisions.",
        },
        {
          desc: "Under the investment advisory services, investment advice is provided to Clients on a non-binding basis. Client may or may not adhere to the advice provided by the Portfolio Manager. All activities pertaining to the execution of transactions, settlement and their reporting are solely the responsibility of the Client. The Portfolio Manager shall only provide advisory services in accordance with the Regulations.",
        },
      ],
    },

    tab1: {
      secTitle: "Why Choose Us.",
      secSubtitle: "Our Best Features",
      tabsnav: [
        {
          title: "Philosophy",
        },
        {
          title: "Values",
        },
        {
          title: "Clients",
        },
      ],

      items: [
        {
            // img: img2,
          desc: "Agreya’s philosophical approach to investment management is deep rooted in the understanding of ‘Risk’ and ‘Returns’ in the market. The strong belief is that there are no absolutes, and every investment approach is relative to, and a function of, the risk-reward metric that it operates on. Over the course of time, the various investment approaches of Agreya Capital have been developed in a systematic way by following a set of founding principles as enumerated below:",
          alphaList: [
            "Sustainability of the approach measured by repeatability of the investment process.",
            " Wealth protection measured by drawdown of portfolio compared to the markets.",
            " Balance between prudence and risk taking measured by relative performances of the investment approaches against the respective benchmarks.",
            " Risk management and mitigation of the portfolio measured by diversification into complementary investment approaches and centred by asset allocation philosophy.",
          ],
          pera: "Based on the above principles, the philosophical approach of Agreya follows a process of:",

          //   button: "Get Started",
          //   buttonurl: "#",
        },
        {
          img: img2,
          desc: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet",
          button: "Get Started",
          img_position: "img_right",
          //   buttonurl: "#",
        },
        {
          img: img2,
          desc: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet",
          button: "Get Started",
          //   buttonurl: "#",
        },
      ],
    },
    tab2: {
      secTitle: "Why People Choose Us.",
      secSubtitle: "Our Best Features.",
      tabnav: [
        {
          title: "Wide Range Of Business Services",
          content:
            "The Argument In Favorite Of Using Filter To Text Goes Some Thing Like This Top",
        },
        {
          title: "Our Best Features For You",
          content:
            "The Argument In Favorite Of Using Filter To Text Goes Some Thing Like This Top",
        },
        {
          title: "Skills To Fulfill Your Jobs",
          content:
            "The Argument In Favorite Of Using Filter To Text Goes Some Thing Like This Top",
        },
        {
          title: "Our Expert Team",
          content:
            "The Argument In Favorite Of Using Filter To Text Goes Some Thing Like This Top",
        },
      ],
      tablists: [
        {
          title: "We Can Give the Best Facilities for Business",
          content:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad dolore eius harum incidunt iste iusto odit quibusdam quis reiciendis vero.",
          lists: [
            {
              icon: <FiLock />,
              title: "Top Security",
              content:
                "The argument in favor of using filler text goes some thing like this",
            },
            {
              icon: <FiBarChart />,
              title: "Revenue Growth",
              content:
                "The argument in favor of using filler text goes some thing like this",
            },
            {
              icon: <FiUserCheck />,
              title: "Risk Manage",
              content:
                "The argument in favor of using filler text goes some thing like this",
            },
            {
              icon: <GiCutDiamond />,
              title: "Fast Support",
              content:
                "The argument in favor of using filler text goes some thing like this",
            },
          ],
        },
        {
          title: "We Can Give the Best Facilities for Business",
          content:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad dolore eius harum incidunt iste iusto odit quibusdam quis reiciendis vero.",
          lists: [
            {
              icon: <FiBarChart />,
              title: "Marketing Strategy",
              content:
                "The argument in favor of using filler text goes some thing like this",
            },
            {
              icon: <IoIosHammer />,
              title: "Best Solutions",
              content:
                "The argument in favor of using filler text goes some thing like this",
            },
            {
              icon: <AiOutlineRocket />,
              title: "Speed Optimization",
              content:
                "The argument in favor of using filler text goes some thing like this",
            },
            {
              icon: <GiCutDiamond />,
              title: "Quick Support",
              content:
                "The argument in favor of using filler text goes some thing like this",
            },
          ],
        },
        {
          title: "We Can Give the Best Facilities for Business",
          content:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad dolore eius harum incidunt iste iusto odit quibusdam quis reiciendis vero.",
          lists: [
            {
              icon: <FiLock />,
              title: "Top Security",
              content:
                "The argument in favor of using filler text goes some thing like this",
            },
            {
              icon: <FiBarChart />,
              title: "Revenue Growth",
              content:
                "The argument in favor of using filler text goes some thing like this",
            },
            {
              icon: <FiUserCheck />,
              title: "Risk Manage",
              content:
                "The argument in favor of using filler text goes some thing like this",
            },
            {
              icon: <GiCutDiamond />,
              title: "Fast Support",
              content:
                "The argument in favor of using filler text goes some thing like this",
            },
          ],
        },
        {
          title: "We Can Give the Best Facilities for Business",
          content:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad dolore eius harum incidunt iste iusto odit quibusdam quis reiciendis vero.",
          lists: [
            {
              icon: <FiBarChart />,
              title: "Marketing Strategy",
              content:
                "The argument in favor of using filler text goes some thing like this",
            },
            {
              icon: <IoIosHammer />,
              title: "Best Solutions",
              content:
                "The argument in favor of using filler text goes some thing like this",
            },
            {
              icon: <AiOutlineRocket />,
              title: "Speed Optimization",
              content:
                "The argument in favor of using filler text goes some thing like this",
            },
            {
              icon: <GiCutDiamond />,
              title: "Quick Support",
              content:
                "The argument in favor of using filler text goes some thing like this",
            },
          ],
        },
      ],
    },
    tab3: {
      tabnav: ["Audit", "Strategy", "Sustainability"],
      tablists: [
        {
          content:
            "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet",
        },
        {
          content:
            "All the Lorem Ipsum generators on the Internet. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.",
        },
        {
          content:
            "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet",
        },
      ],
    },
  },
  CounterUp: {
    secTitle: "Numbers Speak.",
    secSubtitle: "See What Our",
    items: [
      {
        icon: <AiOutlineNotification className="icon" />,
        number: (
          <CountUp
            start={0}
            end={1300}
            duration={8}
            separator=" "
            decimal=","
            prefix=" "
            suffix=" "
          />
        ),
        numsuffix: "+",
        title: "Finishing Projects",
      },
      {
        icon: <AiOutlineUsergroupAdd className="icon" />,
        number: (
          <CountUp
            start={0}
            end={195}
            duration={8}
            separator=" "
            decimal=","
            prefix=" "
            suffix=" "
          />
        ),
        numsuffix: "+",
        title: "Team Members",
      },
      {
        icon: <FaHandshake className="icon" />,
        number: (
          <CountUp
            start={0}
            end={750}
            duration={8}
            separator=" "
            decimal=","
            prefix=" "
            suffix=" "
          />
        ),
        numsuffix: "+",
        title: "Lovely Clients",
      },
      {
        icon: <AiOutlineCheckCircle className="icon" />,
        number: (
          <CountUp
            start={0}
            end={30}
            duration={8}
            separator=" "
            decimal=","
            prefix=" "
            suffix=" "
          />
        ),
        numsuffix: "+",
        title: "Years Of Experience",
      },
    ],
    counterbottomtitle:
      "Do not hesitate to contact us for instant help and support",
    counterbottombtn: "Let's Start Now",
    counterbottombtnurl: "/",
  },
  shopdata: {
    category_collection: {
      secTitle: "Check Out Our Newest items.",
      secSubtitle: "Newest Collection For You",
      left: {
        title: "Product Collection",
        button: "Shop Now",
        img: category1,
        cardlink: "/shop",
      },
      right: {
        title: "Basket Collection",
        button: "Shop Now",
        img: category1,
        cardlink: "/shop",
      },
    },
    sidebar: {
      categories: {
        title: "Categories",
        lists: [
          {
            title: "Accents",
            number: "(11)",
            path: "/product-details",
          },
          {
            title: "Accessories",
            number: "(22)",
            path: "/product-details",
          },
          {
            title: "Bed & Bath",
            number: "(23)",
            path: "/product-details",
          },
          {
            title: "Chair",
            number: "(7)",
            path: "/product-details",
          },
          {
            title: "Clocks",
            number: "(3)",
            path: "/product-details",
          },
          {
            title: "Dining",
            number: "(11)",
            path: "/product-details",
          },
          {
            title: "Furniture",
            number: "(5)",
            path: "/product-details",
          },
          {
            title: "Gifts",
            number: "(31)",
            path: "/product-details",
          },
          {
            title: "Lighting",
            number: "(22)",
            path: "/product-details",
          },
          {
            title: "Smart TVs",
            number: "(13)",
            path: "/product-details",
          },
          {
            title: "Table",
            number: "(3)",
            path: "/product-details",
          },
        ],
      },
      productcolor: {
        title: "Product Color.",
        lists: ["black", "orange", "blue", "darkgray"],
      },
      populartags: {
        title: "Popular Tags",
        lists: [
          {
            title: "Armchairs",
            path: "/product-details",
          },
          {
            title: "Sofas",
            path: "/product-details",
          },
          {
            title: "Wood",
            path: "/product-details",
          },
          {
            title: "Office",
            path: "/product-details",
          },
          {
            title: "Desks",
            path: "/product-details",
          },
          {
            title: "Stools",
            path: "/product-details",
          },
          {
            title: "Footstools",
            path: "/product-details",
          },
          {
            title: "Outdoor",
            path: "/product-details",
          },
          {
            title: "Bedroom",
            path: "/product-details",
          },
        ],
      },
    },
  },
  Testimonialdata: {
    secTitle: "Say About Us.",
    secSubtitle: "See What Our Customers",
    imagesshape: [
      {
        img: img3,
      },
      {
        img: img3,
      },
      {
        img: img3,
      },
      {
        img: img3,
      },
      {
        img: img3,
      },
      {
        img: img3,
      },
      {
        img: img3,
      },
      {
        img: img3,
      },
    ],
  },

  leadershipTeamData: {
    secTitle: "Leadership team",
    secSubtitle: "Meet Our Dedicated",
    teammembers: [
      {
        name: "Anand Kartikeyan",
        designation: "Head – Strategy",
        img: Anand,
        socials: [
          {
            icon: <FaLinkedinIn />,
            url: " https://www.linkedin.com/in/anandkartikeyan?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
          },
        ],
        data: [
          "Anand has 25+ years of corporate and leadership experience across leading organisations such as HSBC, Deutsche Bank, ABN AMRO Bank, ICICI Bank and IndusInd Bank. He has an established track record in managing geographies, P&L responsibilities and teams in the Corporate/Investment Banking and Consulting space. His corporate achievements have included scaling up fledgling businesses, reengineering strategic turnarounds of under-performing units, new client acquisition, business development, complex transaction execution (M&A, leveraged and structured finance), strategy formulation and execution. Trained in psychology, therapy, certifications in executive coaching (ICF-PCC), organizational culture/development and psychometrics. Anand’s additional expertise is in people, culture and leadership domains. Anand is responsible for client interface and heads the transaction advisory practice at Agreya",
          "Anand holds a bachelor’s degree in commerce from University of Madras and a post graduate diploma in management from Symbiosis, Pune. ",
        ],

        buttontext: "Read His Story",
        buttonurl: "/team-details",
      },
      {
        name: " Arjun Narsipur",
        designation: " Co-Chief Investment Officer",
        img: arjun,
        // socials: [
        //   {
        //     icon: <FaLinkedinIn />,
        //     // url: "https://www.linkedin.com/in/vivekvasudevan/",
        //     url:"#"
        //   },
        // ],
        data: [
          "Arjun’s markets career has been mainly with The Hongkong and Shanghai Banking Corporation (“HSBC”) treasury and prime-dealing desks. Arjun has been managing his own portfolio since 2014 and has a deep understanding of all aspects of market analysis, be it fundamental analysis of companies, technical analysis of price movements, studying global macroeconomics and their impact on individual economies and currencies and Indian macro variables and impact on the rupee. Arjun acts as a Co-Chief Investment Officer at Agreya and runs the growth equity investment approach along with the other differentiated investment approaches that uses derivatives. ",
          "Arjun has a bachelor’s degree in engineering from NIT, Bhopal and a post graduate diploma in management from XLRI Jamshedpur.",
        ],

        buttontext: "Read His Story",
        buttonurl: "/team-details",
      },
      {
        name: "M V Krishnan",
        designation: "Managing Partner & COO",
        img: mvk,
        // socials: [
        //   {
        //     icon: <FaLinkedinIn />,
        //     // url: "https://www.linkedin.com/in/krishnan-mv-06845b1/",
        //     url:"#"
        //   },
        // ],
        data: [
          "Krishnan worked with Deutsche Bank AG across foreign exchange, global markets, and corporate coverage for over 13 years before starting his markets career with Agreya Capital as a Co-founder and managing partner. In his last banking role, he was responsible for managing client relationships across South India for large local corporate clients and multinational companies. Prior to Deutsche Bank and his post-graduation, Krishnan worked at Ernst & Young as an Associate in their Corporate Finance Division. At Agreya, Krishnan acts as the Chief Compliance Officer and is responsible for overall organisation as its Chief Operating Officer. ",
          "Krishnan is a Chartered Accountant, has a bachelor’s degree in commerce from University of Madras and a post graduate diploma in management from IIM Indore (Gold Medallist). ",
        ],

        buttontext: "Read His Story",
        buttonurl: "/team-details",
      },
      {
        name: "Nilesh Shevade",
        designation: "Head-Sales and Marketing",
        img: Nilesh,
        socials: [
          {
            icon: <FaLinkedinIn />,
            url: "https://www.linkedin.com/in/nileshshevade?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
          },
        ],

        data: [
          "Nilesh has spent over 20 years specialising into retail businesses. He has worked in the fields of Telecom, Mortgage, Real Estate and Retail Banking. He has built businesses from the ground up as well as large retail franchises with Bharti Group, ICICI Bank, Edelweiss Group & L&T Finance. Over the course of his diverse career, Nilesh has worked extensively with large teams across geographies and built 3-rd party distribution for scaling the business from start up to a large franchise. At Agreya, Nilesh is responsible for business development, sales, marketing, digital presence and building distribution network. ",
          "Nilesh holds a bachelors’ degree in commerce from Symbiosis, Pune and a post graduate degree in management from NMIMS, University of Mumbai. ",
        ],

        buttontext: "Read His Story",
        buttonurl: "/team-details",
      },
      {
        name: "Pankaj Agarwal",
        designation: "Head-Private investments",
        img: Pankaj,
        socials: [
          {
            icon: <FaLinkedinIn />,
            url: " https://www.linkedin.com/in/pankajagarwal/",
          },
        ],
        data: [
          "Pankaj is a serial entrepreneur with over 20 years of experience of building companies in ecommerce, travel and B2B. He started his career working with Indiamart Intermesh Ltd and worked on setting up newer markets in the early years of its expansion. He worked with Citigroup between 2005-2007 helping expand the mortgages portfolio for the Delhi team. He has also been acknowledged as one of the Top 15 Young Entrepreneurs in India by Business World. Pankaj is an alumnus of Delhi University and a post-graduate in management from IIM Indore.",
          "Pankaj has been an avid start-up investor with 100+ investments and is on several advisory boards. He is a partner & heads private investments business at Agreya. He also sits on our advisory board with a focus on new business initiatives and launch of venture capital fund. ",
        ],

        buttontext: "Read His Story",
        buttonurl: "/team-details",
      },
      {
        name: "Shailendra Agarwal",
        designation: "Co-Chief Investment Officer",
        img: shail,
        socials: [
          {
            icon: <FaLinkedinIn />,
            url: "https://www.linkedin.com/in/shailendra-agarwal-5b868136?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
          },
        ],

        data: [
          "Shailendra was Director & Co-Head of India Debt Capital Markets team at Deutsche Bank AG, India for over a decade before co-founding Agreya Capital. Prior to Deutsche Bank, Shailendra has worked with ICICI Bank Limited in their structured products group. Shailendra has been an avid investor in equity markets since 2001 and has fine-tuned his fundamental analysis driven long-term investment approach over the past two decades through a mix of value-driven, growth and special situations stock picking strategies. Shailendra acts as a Co-Chief Investment Officer at Agreya and runs the value equity investment approach. ",
          "Shailendra has a bachelor’s degree in commerce from University of Pune and post graduate diploma in management from IIM, Lucknow. ",
        ],

        buttontext: "Read His Story",
        buttonurl: "/team-details",
      },
      {
        name: "Vivek Vasudevan",
        designation: "Managing Partner & CEO",
        img: vivek,
        socials: [
          {
            icon: <FaLinkedinIn />,
            url: "https://www.linkedin.com/in/vivekvasudevan/",
          },
        ],
        data: [
          "Prior to co-founding Agreya Capital, Vivek was a Director at Metis Family Office wherein he managed business families across the entire spectrum of investment philosophy, asset allocation, investment advisory, family governance and legacy planning in addition to providing Strategic CFO advisory for the underlying businesses. Before joining Metis, Vivek was part of Deutsche Bank AG and ICICI Bank group with 14 years of multi-geographical experience in corporate, investment & transaction banking. Vivek acts as the Principal Officer and CEO for Agreya Capital.",
          "Vivek has a bachelor’s degree in commerce from University of Madras and post graduate degree in management from NMIMS, University of Mumbai.",
        ],

        buttontext: "Read His Story",
        buttonurl: "/team-details",
      },
      
      
      
      
      
      
      
      // {
      //   name: "M S Gopi Krishnan",
      //   designation: "Advisor to the Board",
      //   // img: img4,
      //   img:img32,
      //   socials: [
      //     {
      //       icon: <FaLinkedinIn />,
      //       url: " https://www.linkedin.com/in/gopikrishnan-mahadhanapuram-18a48b157?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
      //     },
      //   ],
      //   data: [
      //     "Gopikrishnan M S is a Banking & Finance professional and a Treasury expert with more than 25 years of experience in FX, Rates & Credit markets. Starting his career with IDBI group, he set-up the Primary Dealership (PD) Business and grew it to the top-rated PD in the country. He ran the Currencies and Rates business in Deutsche Bank India for 8 years before moving to Standard Chartered Bank where he joined as the Head of Macro Trading for South Asia and grew over the years to Head Financial Markets for South Asia.",
      //     "Gopikrishnan is a well-known face in the Financial Markets and has regularly appeared on media to share his views on the markets. He has been a key player in influencing policy decisions with respect to markets by closely interacting with policy makers in RBI, SEBI, MoF etc. He was the Vice-Chairman of Foreign Exchange Dealers Association of India (FEDAI) and was a board member of Fixed Income & Money Market Dealers Association (FIMMDA). He is currently appointed to SEBI’s Hybrid Securities Advisory Committee. ",
      //     "Currently he acts as an Independent Financial and Business Advisor to Boutique Fund Houses, Banks, Family offices, start-ups etc. At Agreya, he advises the Co-founders on macros and provides key insights on debt capital markets. ",
      //   ],

      //   buttontext: "Read His Story",
      //   buttonurl: "/team-details",
      // },
      // {
      //   name: "Krishnaprasad Ramanathan",
      //   designation: "Advisor – Business Partnerships",
      //   // img: img4,
      //   img: img4,
      //   socials: [
      //     {
      //       icon: <FaLinkedinIn />,
      //       url: "#",
      //     },
      //   ],
      //   data: [
      //     "Krishnaprasad, fondly referred as KP, comes with over two decades of experience across senior management and leadership roles across investment management, private banking and wealth advisory. He has previously worked with Franklin Templeton Investments, HSBC and Centrum Private Wealth. KP has been on the several advisory boards including Auxano Angel Fund, Credence Family Office, TIW Private Equity, CFO Bridge amongst others. He is a Venture Capital Evangelist and has several investments with concurrent advisory roles in many. KP has a bachelor’s degree in commerce and post graduate degree in management from University of Mumbai. ",
      //     "At Agreya, KP’s advisory role traverses business partnerships, overseas initiatives, family office distribution and geographical outreach. ",
      //   ],

      //   buttontext: "Read His Story",
      //   buttonurl: "/team-details",
      // },
      
    ],

    // teamDetails: {
    //   img: img12,
    //   name: "Colling Roberts.",
    //   designation: "Founder & CEO",
    //   address: "Regional Leader Switzerland, Italy, Austria",
    //   phonenumber: "+123-134-6849",
    //   email: "example@gmail.com",
    //   website: "www.collinroberts.com",
    //   expertise: {
    //     title: "Areas Of Expertise",
    //     lists: [
    //       "Business transformation",
    //       "Restructuring and turnaround",
    //       "Integration",
    //       "Growth strategy",
    //     ],
    //   },
    //   educations: {
    //     title: "Education",
    //     lists: [
    //       "MBA, Rotterdam School of Management, Erasmus University",
    //       "BS, Engineering, Technical University of Denmark",
    //     ],
    //   },
    //   bottomContent: {
    //     title: "A Little Description About Me.",
    //     content1:
    //       "With over 20 years of experience in entrepreneurship, management, business planning, financial analysis, software engineering, operations, and decision analysis, Brandon has the breadth and depth of experience needed to quickly understand entrepreneurs businesses and craft the most suitable solutions.",
    //     content2:
    //       "Investing atones demons trave runt lectores legere liusry quod ilegunt saepies claritas Inves tig ationes. Our management team is led by the Managing Partner Peter Mockler who successfully accomplished the European management buyout. My focus areas reorganization of marketing and customer services and restructuring. Throughout my pro fessional career, I led various global consulting projects, launched new marketing concepts and managed implementations. Throughout my pro fessional career, I led various global consulting projects, launched new marketing concepts.",
    //     blockquote: {
    //       content:
    //         "There are no secrets to success. It is the result of preparation, hard work, and learning from failure. lorem ipsum dolor sit amet.",
    //       name: "Amand Seyfrid,",
    //       designation: "Sales & Marketing, Alien Ltc.",
    //     },
    //   },
    //   sidebar: {
    //     needhelp: {
    //       title: "Need Any Help?",
    //       content:
    //         "Contact us at the Consulting office nearest to you or submit a business inquiry online",
    //       button: "Contact Us",
    //       buttonurl: "/contact",
    //     },
    //     companybtn: "Company Presentation",
    //     companybtnurl: "/", // Enter download file url here
    //   },
    // },
  },
  advisoryTeam: {
    secTitle: "Advisory Team",
    // secSubtitle: "Meet Our Dedicated",
    teammembers: [
      // {
      //   name: "Pankaj Agarwal",
      //   designation: "Partner",
      //   img: img4,
      //   socials: [
      //     {
      //       icon: <FaLinkedinIn />,
      //       url: " https://www.linkedin.com/in/pankajagarwal/",
      //     },
      //   ],
      //   data: [
      //     "Pankaj is a serial entrepreneur with over 15 years of experience of building companies in ecommerce, travel and B2B. He started his career working with Indiamart Intermesh Ltd and worked on setting up newer markets in the early years of its expansion. He worked with Citigroup between 2005-2007 helping expand the mortgages portfolio for the Delhi team. He has also been acknowledged as one of the Top 15 Young Entrepreneurs in India by Business World. Pankaj is an alumnus of Delhi University and a post-graduate in management from IIM Indore.",
      //     "Pankaj has been an avid start-up investor across various digital and ecomm companies and is on several advisory boards of such start-ups. He is a minority investor-partner at Agreya and sits on the advisory board of Agreya advising on new business initiatives and launch of venture capital fund. ",
      //   ],
      // {
      //   name: "Pankaj Agarwal",
      //   designation: "Partner",
      //   img: img4,
      //   socials: [
      //     {
      //       icon: <FaLinkedinIn />,
      //       url: " https://www.linkedin.com/in/pankajagarwal/",
      //     },
      //   ],
      //   data: [
      //     "Pankaj is a serial entrepreneur with over 15 years of experience of building companies in ecommerce, travel and B2B. He started his career working with Indiamart Intermesh Ltd and worked on setting up newer markets in the early years of its expansion. He worked with Citigroup between 2005-2007 helping expand the mortgages portfolio for the Delhi team. He has also been acknowledged as one of the Top 15 Young Entrepreneurs in India by Business World. Pankaj is an alumnus of Delhi University and a post-graduate in management from IIM Indore.",
      //     "Pankaj has been an avid start-up investor across various digital and ecomm companies and is on several advisory boards of such start-ups. He is a minority investor-partner at Agreya and sits on the advisory board of Agreya advising on new business initiatives and launch of venture capital fund. ",
      //   ],

      //   buttontext: "Read His Story",
      //   buttonurl: "/team-details",
      // },
      // {
      //   name: "M S Gopi Krishnan",
      //   designation: "Advisor – Policy, Interest Rates and Market Outlook",
      //   // img: img4,
      //   img:img32,
      //   socials: [
      //     {
      //       icon: <FaLinkedinIn />,
      //       url: " https://www.linkedin.com/in/gopikrishnan-mahadhanapuram-18a48b157?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
      //     },
      //   ],
      //   data: [
      //     "Gopikrishnan M S is a Banking & Finance professional and a Treasury expert with more than 25 years of experience in FX, Rates & Credit markets. Starting his career with IDBI group, he set-up the Primary Dealership (PD) Business and grew it to the top-rated PD in the country. He ran the Currencies and Rates business in Deutsche Bank India for 8 years before moving to Standard Chartered Bank where he joined as the Head of Macro Trading for South Asia and grew over the years to Head Financial Markets for South Asia.",
      //     "Gopikrishnan is a well-known face in the Financial Markets and has regularly appeared on media to share his views on the markets. He has been a key player in influencing policy decisions with respect to markets by closely interacting with policy makers in RBI, SEBI, MoF etc. He was the Vice-Chairman of Foreign Exchange Dealers Association of India (FEDAI) and was a board member of Fixed Income & Money Market Dealers Association (FIMMDA). He is currently appointed to SEBI’s Hybrid Securities Advisory Committee. ",
      //     "Currently he acts as an Independent Financial and Business Advisor to Boutique Fund Houses, Banks, Family offices, start-ups etc. At Agreya, he advises the Co-founders on macros and provides key insights on debt capital markets. ",
      //   ],
      //   buttontext: "Read His Story",
      //   buttonurl: "/team-details",
      // },
      // {
      //   name: "M S Gopi Krishnan",
      //   designation: "Advisor – Policy, Interest Rates and Market Outlook",
      //   // img: img4,
      //   img:img32,
      //   socials: [
      //     {
      //       icon: <FaLinkedinIn />,
      //       url: " https://www.linkedin.com/in/gopikrishnan-mahadhanapuram-18a48b157?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
      //     },
      //   ],
      //   data: [
      //     "Gopikrishnan M S is a Banking & Finance professional and a Treasury expert with more than 25 years of experience in FX, Rates & Credit markets. Starting his career with IDBI group, he set-up the Primary Dealership (PD) Business and grew it to the top-rated PD in the country. He ran the Currencies and Rates business in Deutsche Bank India for 8 years before moving to Standard Chartered Bank where he joined as the Head of Macro Trading for South Asia and grew over the years to Head Financial Markets for South Asia.",
      //     "Gopikrishnan is a well-known face in the Financial Markets and has regularly appeared on media to share his views on the markets. He has been a key player in influencing policy decisions with respect to markets by closely interacting with policy makers in RBI, SEBI, MoF etc. He was the Vice-Chairman of Foreign Exchange Dealers Association of India (FEDAI) and was a board member of Fixed Income & Money Market Dealers Association (FIMMDA). He is currently appointed to SEBI’s Hybrid Securities Advisory Committee. ",
      //     "Currently he acts as an Independent Financial and Business Advisor to Boutique Fund Houses, Banks, Family offices, start-ups etc. At Agreya, he advises the Co-founders on macros and provides key insights on debt capital markets. ",
      //   ],

      //   buttontext: "Read His Story",
      //   buttonurl: "/team-details",
      // },
      // {
      //   name: "Krishnaprasad Ramanathan",
      //   designation: "Advisor – Business Partnerships",
      //   // img: img4,
      //   img: img4,
      //   socials: [
      //     {
      //       icon: <FaLinkedinIn />,
      //       url: "#",
      //     },
      //   ],
      //   data: [
      //     "Krishnaprasad, fondly referred as KP, comes with over two decades of experience across senior management and leadership roles across investment management, private banking and wealth advisory. He has previously worked with Franklin Templeton Investments, HSBC and Centrum Private Wealth. KP has been on the several advisory boards including Auxano Angel Fund, Credence Family Office, TIW Private Equity, CFO Bridge amongst others. He is a Venture Capital Evangelist and has several investments with concurrent advisory roles in many. KP has a bachelor’s degree in commerce and post graduate degree in management from University of Mumbai. ",
      //     "At Agreya, KP’s advisory role traverses business partnerships, overseas initiatives, family office distribution and geographical outreach. ",
      //   ],
      //   buttontext: "Read His Story",
      //   buttonurl: "/team-details",
      // },
      // {
      //   name: "Krishnaprasad Ramanathan",
      //   designation: "Advisor – Business Partnerships",
      //   // img: img4,
      //   img: img4,
      //   socials: [
      //     {
      //       icon: <FaLinkedinIn />,
      //       url: "#",
      //     },
      //   ],
      //   data: [
      //     "Krishnaprasad, fondly referred as KP, comes with over two decades of experience across senior management and leadership roles across investment management, private banking and wealth advisory. He has previously worked with Franklin Templeton Investments, HSBC and Centrum Private Wealth. KP has been on the several advisory boards including Auxano Angel Fund, Credence Family Office, TIW Private Equity, CFO Bridge amongst others. He is a Venture Capital Evangelist and has several investments with concurrent advisory roles in many. KP has a bachelor’s degree in commerce and post graduate degree in management from University of Mumbai. ",
      //     "At Agreya, KP’s advisory role traverses business partnerships, overseas initiatives, family office distribution and geographical outreach. ",
      //   ],

      //   buttontext: "Read His Story",
      //   buttonurl: "/team-details",
      // },
      //   buttontext: "Read His Story",
      //   buttonurl: "/team-details",
      // },
    ],

    // teamDetails: {
    //   img: img12,
    //   name: "Colling Roberts.",
    //   designation: "Founder & CEO",
    //   address: "Regional Leader Switzerland, Italy, Austria",
    //   phonenumber: "+123-134-6849",
    //   email: "example@gmail.com",
    //   website: "www.collinroberts.com",
    //   expertise: {
    //     title: "Areas Of Expertise",
    //     lists: [
    //       "Business transformation",
    //       "Restructuring and turnaround",
    //       "Integration",
    //       "Growth strategy",
    //     ],
    //   },
    //   educations: {
    //     title: "Education",
    //     lists: [
    //       "MBA, Rotterdam School of Management, Erasmus University",
    //       "BS, Engineering, Technical University of Denmark",
    //     ],
    //   },
    //   bottomContent: {
    //     title: "A Little Description About Me.",
    //     content1:
    //       "With over 20 years of experience in entrepreneurship, management, business planning, financial analysis, software engineering, operations, and decision analysis, Brandon has the breadth and depth of experience needed to quickly understand entrepreneurs businesses and craft the most suitable solutions.",
    //     content2:
    //       "Investing atones demons trave runt lectores legere liusry quod ilegunt saepies claritas Inves tig ationes. Our management team is led by the Managing Partner Peter Mockler who successfully accomplished the European management buyout. My focus areas reorganization of marketing and customer services and restructuring. Throughout my pro fessional career, I led various global consulting projects, launched new marketing concepts and managed implementations. Throughout my pro fessional career, I led various global consulting projects, launched new marketing concepts.",
    //     blockquote: {
    //       content:
    //         "There are no secrets to success. It is the result of preparation, hard work, and learning from failure. lorem ipsum dolor sit amet.",
    //       name: "Amand Seyfrid,",
    //       designation: "Sales & Marketing, Alien Ltc.",
    //     },
    //   },
    //   sidebar: {
    //     needhelp: {
    //       title: "Need Any Help?",
    //       content:
    //         "Contact us at the Consulting office nearest to you or submit a business inquiry online",
    //       button: "Contact Us",
    //       buttonurl: "/contact",
    //     },
    //     companybtn: "Company Presentation",
    //     companybtnurl: "/", // Enter download file url here
    //   },
    // },
  },
  Teammemberdata: {
    secTitle: "Expert Team Members.",
    secSubtitle: "Meet Our Dedicated",
    teammembers: [
      {
        name: "Tanbir Ahmed",
        designation: "Director",
        img: img4,
        socials: [
          {
            icon: <FaFacebookF />,
            url: "https://facebook.com",
          },
          {
            icon: <FaTwitter />,
            url: "https://twitter.com",
          },
          {
            icon: <FaLinkedinIn />,
            url: "https://linkedin.com",
          },
          {
            icon: <FaYoutube />,
            url: "https://youtube.com",
          },
        ],
        buttontext: "Read His Story",
        buttonurl: "/team-details",
      },
      {
        name: "Adam Smith",
        designation: "Director",
        img: img4,
        socials: [
          {
            icon: <FaFacebookF />,
            url: "https://facebook.com",
          },
          {
            icon: <FaTwitter />,
            url: "https://twitter.com",
          },
          {
            icon: <FaLinkedinIn />,
            url: "https://linkedin.com",
          },
          {
            icon: <FaYoutube />,
            url: "https://youtube.com",
          },
        ],
        buttontext: "Read His Story",
        buttonurl: "/team-details",
      },
      {
        name: "Pam Sharon",
        designation: "Director",
        img: img4,
        socials: [
          {
            icon: <FaFacebookF />,
            url: "https://facebook.com",
          },
          {
            icon: <FaTwitter />,
            url: "https://twitter.com",
          },
          {
            icon: <FaLinkedinIn />,
            url: "https://linkedin.com",
          },
          {
            icon: <FaYoutube />,
            url: "https://youtube.com",
          },
        ],
        buttontext: "Read His Story",
        buttonurl: "/team-details",
      },
    ],
    moreTeam: [
      {
        name: "Tanbir Ahmed",
        designation: "Director",
        img: img4,
        socials: [
          {
            icon: <FaFacebookF />,
            url: "https://facebook.com",
          },
          {
            icon: <FaTwitter />,
            url: "https://twitter.com",
          },
          {
            icon: <FaLinkedinIn />,
            url: "https://linkedin.com",
          },
          {
            icon: <FaYoutube />,
            url: "https://youtube.com",
          },
        ],
        buttontext: "Read His Story",
        buttonurl: "/team-details",
      },
      {
        name: "Adam Smith",
        designation: "Director",
        img: img4,
        socials: [
          {
            icon: <FaFacebookF />,
            url: "https://facebook.com",
          },
          {
            icon: <FaTwitter />,
            url: "https://twitter.com",
          },
          {
            icon: <FaLinkedinIn />,
            url: "https://linkedin.com",
          },
          {
            icon: <FaYoutube />,
            url: "https://youtube.com",
          },
        ],
        buttontext: "Read His Story",
        buttonurl: "/team-details",
      },
      {
        name: "Pam Sharon",
        designation: "Director",
        img: img4,
        socials: [
          {
            icon: <FaFacebookF />,
            url: "https://facebook.com",
          },
          {
            icon: <FaTwitter />,
            url: "https://twitter.com",
          },
          {
            icon: <FaLinkedinIn />,
            url: "https://linkedin.com",
          },
          {
            icon: <FaYoutube />,
            url: "https://youtube.com",
          },
        ],
        buttontext: "Read His Story",
        buttonurl: "/team-details",
      },
      {
        name: "Domien Doe",
        designation: "Junior Designer",
        img: img4,
        socials: [
          {
            icon: <FaFacebookF />,
            url: "https://facebook.com",
          },
          {
            icon: <FaTwitter />,
            url: "https://twitter.com",
          },
          {
            icon: <FaLinkedinIn />,
            url: "https://linkedin.com",
          },
          {
            icon: <FaYoutube />,
            url: "https://youtube.com",
          },
        ],
        buttontext: "Read His Story",
        buttonurl: "/team-details",
      },
      {
        name: "Pam Sharon",
        designation: "Director",
        img: img4,
        socials: [
          {
            icon: <FaFacebookF />,
            url: "https://facebook.com",
          },
          {
            icon: <FaTwitter />,
            url: "https://twitter.com",
          },
          {
            icon: <FaLinkedinIn />,
            url: "https://linkedin.com",
          },
          {
            icon: <FaYoutube />,
            url: "https://youtube.com",
          },
        ],
        buttontext: "Read His Story",
        buttonurl: "/team-details",
      },
      {
        name: "Richard Pam",
        designation: "Director",
        img: img4,
        socials: [
          {
            icon: <FaFacebookF />,
            url: "https://facebook.com",
          },
          {
            icon: <FaTwitter />,
            url: "https://twitter.com",
          },
          {
            icon: <FaLinkedinIn />,
            url: "https://linkedin.com",
          },
          {
            icon: <FaYoutube />,
            url: "https://youtube.com",
          },
        ],
        buttontext: "Read His Story",
        buttonurl: "/team-details",
      },
    ],
    teamDetails: {
      img: img12,
      name: "Colling Roberts.",
      designation: "Founder & CEO",
      address: "Regional Leader Switzerland, Italy, Austria",
      phonenumber: "+123-134-6849",
      email: "example@gmail.com",
      website: "www.collinroberts.com",
      expertise: {
        title: "Areas Of Expertise",
        lists: [
          "Business transformation",
          "Restructuring and turnaround",
          "Integration",
          "Growth strategy",
        ],
      },
      educations: {
        title: "Education",
        lists: [
          "MBA, Rotterdam School of Management, Erasmus University",
          "BS, Engineering, Technical University of Denmark",
        ],
      },
      bottomContent: {
        title: "A Little Description About Me.",
        content1:
          "With over 20 years of experience in entrepreneurship, management, business planning, financial analysis, software engineering, operations, and decision analysis, Brandon has the breadth and depth of experience needed to quickly understand entrepreneurs businesses and craft the most suitable solutions.",
        content2:
          "Investing atones demons trave runt lectores legere liusry quod ilegunt saepies claritas Inves tig ationes. Our management team is led by the Managing Partner Peter Mockler who successfully accomplished the European management buyout. My focus areas reorganization of marketing and customer services and restructuring. Throughout my pro fessional career, I led various global consulting projects, launched new marketing concepts and managed implementations. Throughout my pro fessional career, I led various global consulting projects, launched new marketing concepts.",
        blockquote: {
          content:
            "There are no secrets to success. It is the result of preparation, hard work, and learning from failure. lorem ipsum dolor sit amet.",
          name: "Amand Seyfrid,",
          designation: "Sales & Marketing, Alien Ltc.",
        },
      },
      sidebar: {
        needhelp: {
          title: "Need Any Help?",
          content:
            "Contact us at the Consulting office nearest to you or submit a business inquiry online",
          button: "Contact Us",
          buttonurl: "/contact",
        },
        companybtn: "Company Presentation",
        companybtnurl: "/", // Enter download file url here
      },
    },
  },
  faqdata: [
    {
      title: "How do I find my Windows product key?",
      content:
        "Investiga tiones demonstr averun lectores legere melius quod kequa legunt saepius. Claritas est etiam pro cessus dynamicus qui sequitur mutatin onem consuetudium. Investiga tiones demonstr averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium.",
    },
    {
      title: "Do you have any built-in caching?",
      content:
        "Investiga tiones demonstr averun lectores legere melius quod kequa legunt saepius. Claritas est etiam pro cessus dynamicus qui sequitur mutatin onem consuetudium. Investiga tiones demonstr averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium.",
    },
    {
      title: "Can i add/upgrade my plan at any time?",
      content:
        "Investiga tiones demonstr averun lectores legere melius quod kequa legunt saepius. Claritas est etiam pro cessus dynamicus qui sequitur mutatin onem consuetudium. Investiga tiones demonstr averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium.",
    },
    {
      title: "What access comes with my hosting plan?",
      content:
        "Investiga tiones demonstr averun lectores legere melius quod kequa legunt saepius. Claritas est etiam pro cessus dynamicus qui sequitur mutatin onem consuetudium. Investiga tiones demonstr averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium.",
    },
    {
      title: "How do i change my password?",
      content:
        "Investiga tiones demonstr averun lectores legere melius quod kequa legunt saepius. Claritas est etiam pro cessus dynamicus qui sequitur mutatin onem consuetudium. Investiga tiones demonstr averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium.",
    },
    {
      title: "What's included?",
      content:
        "Investiga tiones demonstr averun lectores legere melius quod kequa legunt saepius. Claritas est etiam pro cessus dynamicus qui sequitur mutatin onem consuetudium. Investiga tiones demonstr averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium.",
    },
    {
      title: "What services do you provide?",
      content:
        "Investiga tiones demonstr averun lectores legere melius quod kequa legunt saepius. Claritas est etiam pro cessus dynamicus qui sequitur mutatin onem consuetudium. Investiga tiones demonstr averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium.",
    },
    {
      title: "How much will it cost?",
      content:
        "Investiga tiones demonstr averun lectores legere melius quod kequa legunt saepius. Claritas est etiam pro cessus dynamicus qui sequitur mutatin onem consuetudium. Investiga tiones demonstr averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium.",
    },
  ],
  ctadata: {
    cta1: {
      title: "Subscribe Newsletter to Get Latest Discount.",
      content:
        "Be the first to hear about our latest products & offers. As a welcome gift, we’ll email you a 20% OFF discount code.",
    },
  },
  Chartareadata: {
    secTitle: "Better Results.",
    secSubtitle: "Our Growth",
    title1: "Increase Sales",
    content1:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem eaque ipsa quae ab illo inventore sit amet, consectetur adipisicing elit. Sit, vitae.",
    title2: "Real-Time Analytics",
    content2:
      "Nullam sollicitudin nisl at nunc tempor pulvindolor sit amet, adipiscinar. Quisque gravida, tellus egett vehicula fel.",
    buttontext: "Learn More",
    buttonurl: "/about",
  },
  Pricingdata: {
    secTitle: "Choose Your Plan.",
    secSubtitle: "Affordable Packages",
    tablist: ["Monthly", "Yearly"],
    planlists: [
      {
        plans: [
          {
            title: "Basic",
            price: "19",
            currency: "$",
            mo: "/Monthly",
            features: [
              "Limitless concepts",
              "Community access",
              "Expert Reviews",
              "Creative Layouts",
              "Free Support",
              "Free Fonts",
            ],
            buttontext: "Get Started",
            buttonurl: "/",
            isactive: false,
          },
          {
            title: "Popular",
            price: "39",
            currency: "$",
            mo: "/Monthly",
            features: [
              "Limitless concepts",
              "Community access",
              "Expert Reviews",
              "Creative Layouts",
              "Free Support",
              "Free Fonts",
            ],
            buttontext: "Get Started",
            buttonurl: "/",
            isactive: true,
          },
          {
            title: "Premium",
            price: "78",
            currency: "$",
            mo: "/Monthly",
            features: [
              "Limitless concepts",
              "Community access",
              "Expert Reviews",
              "Creative Layouts",
              "Free Support",
              "Free Fonts",
            ],
            buttontext: "Get Started",
            buttonurl: "/",
            isactive: false,
          },
        ],
      },
      {
        plans: [
          {
            title: "Basic",
            price: "200",
            currency: "$",
            mo: "/Year(save 20%)",
            features: [
              "Limitless concepts",
              "Community access",
              "Expert Reviews",
              "Creative Layouts",
              "Free Support",
              "Free Fonts",
            ],
            buttontext: "Get Started",
            buttonurl: "/",
            isactive: false,
          },
          {
            title: "Popular",
            price: "460",
            currency: "$",
            mo: "/Year(save 20%)",
            features: [
              "Limitless concepts",
              "Community access",
              "Expert Reviews",
              "Creative Layouts",
              "Free Support",
              "Free Fonts",
            ],
            buttontext: "Get Started",
            buttonurl: "/",
            isactive: true,
          },
          {
            title: "Premium",
            price: "300",
            currency: "$",
            mo: "/Year(save 20%)",
            features: [
              "Limitless concepts",
              "Community access",
              "Expert Reviews",
              "Creative Layouts",
              "Free Support",
              "Free Fonts",
            ],
            buttontext: "Get Started",
            buttonurl: "/",
            isactive: false,
          },
        ],
      },
    ],
  },
  errorpagedata: {
    img: errorimg,
    title: "Opps! Page not found.",
    content:
      "The page you are looking for might have been removed, had its name changed, or is temporarily unavailable. You can check out our Help Center",
    connector: "or",
    backbtn: "Back To Home",
    footermenu: [
      {
        title: "Home",
        path: "/",
      },
      {
        title: "About Us",
        path: "/about",
      },
      {
        title: "Service",
        path: "/services",
      },
      {
        title: "Contact",
        path: "/contact",
      },
    ],
  },
  contactdata: {
    secTitle: "Do You Have Any Question? Write Us a Message.",
    secSubtitle: "Get In Touch With Us",
    content:
      "Lorem ipsum is simply free text dolor sit amet quie adipiscing elit. When an unknown pricter took a gallery. quiaies lipsum dolor sit atur adip scing",
    socials: [
      {
        icon: <FaFacebookF />,
        url: "https://facebook.com",
      },
      {
        icon: <FaTwitter />,
        url: "https://twitter.com",
      },
      {
        icon: <FaInstagram />,
        url: "https://instagram.com",
      },
      {
        icon: <FaLinkedinIn />,
        url: "https://linkedin.com",
      },
      {
        icon: <FaGooglePlusG />,
        url: "https://plus.google.com",
      },
    ],
    sidebar: {
      title: "Our Offices",
      img: img10,
      content:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci culpa dolorum explicabo id incidunt iure",
      address: "Melbourne, Australia, 105 South Park Avenue",
      phonenum: "2800 256 508",
      phonenum2: "666 777 888",
      email: "Needhelp@minzel.com",
      email2: "inquiry@minzel.com",
      button: "Contact With Us",
    },
  },
  performanceTable: [
    {
      "InvestmentApproachvis-à-visBenchmark": "Concentrated Value Discovery",
      "AUM(INRCrs)": 85.38,
      "1month": -0.1084,
      "3month": -0.04,
      "6months": -0.058,
      "1year": 0.122,
      "3years": 0.296,
      SinceInception: 0.251,
    },
    {
      "InvestmentApproachvis-à-visBenchmark": "BSE 500",
      "AUM(INRCrs)": "-",
      "1month": -0.0448,
      "3month": -0.0107,
      "6months": -0.0335,
      "1year": 0.0686,
      "3years": 0.132,
      SinceInception: 0.1089,
    },
    {
      "InvestmentApproachvis-à-visBenchmark": "Diversified Growth Strategy",
      "AUM(INRCrs)": 46.62,
      "1month": -0.0473,
      "3month": -0.009,
      "6months": -0.078,
      "1year": -0.0244,
      "3years": 0.179,
      SinceInception: 0.1,
    },
    {
      "InvestmentApproachvis-à-visBenchmark": "BSE 500",
      "AUM(INRCrs)": "-",
      "1month": -0.0448,
      "3month": -0.0107,
      "6months": -0.0335,
      "1year": 0.0686,
      "3years": 0.132,
      SinceInception: 0.1089,
    },
    {
      "InvestmentApproachvis-à-visBenchmark":
        "Dynamic Alpha Mutual Fund Strategy",
      "AUM(INRCrs)": 114.33,
      "1month": -0.0223,
      "3month": -0.0071,
      "6months": -0.0116,
      "1year": 0.096,
      "Snce Inception": 0.0986,
    },
    {
      "InvestmentApproachvis-à-visBenchmark":
        "CRISIL Liquid Fund Index-70%, BSE500-30%",
      "AUM(INRCrs)": "-",
      "1month": -0.0112,
      "3month": 0.0036,
      "6months": 0.0033,
      "1year": 0.0467,
      "Snce Inception": 0.0803,
    },
    {
      "InvestmentApproachvis-à-visBenchmark":
        "Limited Exposure Equity Index Strategy",
      "AUM(INRCrs)": 90.26,
      "1month": -0.0191,
      "3month": -0.027,
      "6months": 0.012,
      "1year": 0.0847,
      "3years": "-",
      SinceInception: 0.168,
    },
    {
      "InvestmentApproachvis-à-visBenchmark": "Nifty50-50%, Bank Nifty-50%",
      "AUM(INRCrs)": "-",
      "1month": -0.0235,
      "3month": -0.0161,
      "6months": -0.0146,
      "1year": 0.0316,
      "3years": "-",
      SinceInception: 0.0764,
    },
    {
      "InvestmentApproachvis-à-visBenchmark": "Agreya Index Multiplier",
      "AUM(INRCrs)": 72.97,
      "1month": -0.074,
      "3month": -0.051,
      "6months": -0.076,
      "1year": 0.038,
      "3years": "-",
      SinceInception: 0.1191,
    },
    {
      "InvestmentApproachvis-à-visBenchmark": "Nifty50",
      "AUM(INRCrs)": "-",
      "1month": -0.0303,
      "3month": -0.013,
      "6months": -0.0235,
      "1year": 0.0643,
      "3years": "-",
      SinceInception: 0.083,
    },
    {
      "InvestmentApproachvis-à-visBenchmark":
        "Portfolio Return Optimiser Strategy",
      "AUM(INRCrs)": 60.69,
      "1month": 0.0179,
      "3month": "-",
      "6months": "-",
      "1year": "-",
      "3years": "-",
      SinceInception: 0.0185,
    },
    {
      "InvestmentApproachvis-à-visBenchmark": "Nifty50-50%, Bank Nifty-50%",
      "AUM(INRCrs)": "-",
      "1month": -0.0235,
      "6months": "-",
      "1 year": "-",
      "3years": "-",
      "Snce Inception": 0.0043,
    },
    {
      "InvestmentApproachvis-à-visBenchmark": "Non-Discretionary PMS",
      "AUM(INRCrs)": "-",
      "1month": "-",
      "3month": "-",
      "6months": "-",
      "1year": "-",
      "3years": "-",
      SinceInception: "-",
    },
    {
      "InvestmentApproachvis-à-visBenchmark": "Assets under Advisory",
      "AUM(INRCrs)": 18.28,
      "1month": "-",
      "3month": "-",
      "6months": "-",
      "1year": "-",
      "3years": "-",
      SinceInception: "-",
    },
    {
      "InvestmentApproachvis-à-visBenchmark": "Total",
      "AUM(INRCrs)": 486.53,
      "1month": "-",
      "3month": "-",
      "6months": "-",
      "1year": "-",
      "3years": "-",
      SinceInception: "-",
    },
  ],
  Footerdata: {
    ftLeftwidget: {
      footerlogo: footerLogo,
      content: " ",
      phonenumber: "+91-80-41690158",
      email: "info@agreyacapital.com",
      address:
        "Agreya Capital Advisors LLP 360, 6th Main, 1st Block, Koramangala, Bengaluru - 560 034",
      socials: {
        title: "Follow & Connect On.",
        lists: [
          {
            icon: <FaFacebookF />,
            url: "https://facebook.com",
          },
          {
            icon: <FaTwitter />,
            url: "https://twitter.com",
          },
          {
            icon: <FaInstagram />,
            url: "https://instagram.com",
          },
          {
            icon: <FaLinkedinIn />,
            url: "https://linkedin.com",
          },
          {
            icon: <FaYoutube />,
            url: "https://youtube.com",
          },
        ],
      },
    },
    ftCommonLinks: {
      title: "Need Help?",
      lists: [
        {
          title: "Community",
          url: "/services",
        },
        {
          title: "About Us",
          url: "/about",
        },
        {
          title: "Our Advisors",
          url: "/services",
        },
        {
          title: "Jobs",
          url: "/",
        },
        {
          title: "Keep In Touch",
          url: "/contact",
        },
        {
          title: "Forums",
          url: "/",
        },
        {
          title: "Contact Us",
          url: "/contact",
        },
      ],
    },
    ftSchedule: {
      title: "Working Hours.",
      offday: "Sat-Sun:",
      litss: [
        {
          day: "Monday:",
          time: "9am-4pm",
        },
        {
          day: "Tuesday:",
          time: "9am-4pm",
        },
        {
          day: "Wednesday:",
          time: "9am-4pm",
        },
        {
          day: "Thursday:",
          time: "9am-4pm",
        },
        {
          day: "Friday:",
          time: "9am-4pm",
        },
      ],
    },
  },
};
export default sectiondata;
