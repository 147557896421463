import React from "react";
import Navbar from "../components/common/Navbar";
import Breadcrumb from "../components/common/Breadcrumb";
import About from "../components/about/About";
import ServiceSection2 from "../sections/services/ServiceSection2";
import SimpleTab from "../components/tabs/SimpleTab";
import CounterUp from "../sections/CounterUp";
import Team from "../components/team/Team";
import Clients from "../sections/Clients";
import CtaForm from "../components/other/CtaForm";
import Footer from "../components/other/Footer";
import sectiondata from "../store/store";
import AboutPgTabs from "../components/tabs/AboutPgTabs";
import { Link } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import HeroSlider from "../components/sliders/HeroSlider";
import GetInTouchButton from "../components/about/GetInTouchButton";
export default function About_Page() {
  const slideItems = sectiondata.Sliders.heroSlider;
  return (
    <main className="about-page">
      {/* Header */}
      <header className="header-area">
        <Navbar />
      </header>

      {/* Breadcrumb */}
      {/* <Breadcrumb title="About Us." /> */}
      {/* About 2 */}
      <About />

      {/* Services */}
      {/* <ServiceSection2 servicelist={sectiondata.Services.Service3} /> */}

      {/* Why Choose Us */}
      {/* <SimpleTab
        secTitle={sectiondata.Tabdata.tab1.secTitle}
        secSubtitle={sectiondata.Tabdata.tab1.secSubtitle}
        tabslist={sectiondata.Tabdata.tab1.items}
        tabsnav={sectiondata.Tabdata.tab1.tabsnav}
      /> */}
       {/* <div className="cta1-buttons">
          {
            <Link to={"/approaches"} className="theme-button">Know our investment approaches <FiChevronRight className="icon" /></Link>
          }
      </div> */}
  {/* <div className="cta-buttons">
        {/* This Link will navigate to the section with the "Get in Touch" button */}
        {/* <Link to="/" className="theme-button">
          Get in Touch
        </Link> */}
        {/* </div> */}
        {/* <div  className="cta-buttons">
 
      <GetInTouchButton />
    </div> */}
      <div className="get-in-touch-container">
        <GetInTouchButton />
      </div>
      <AboutPgTabs
      
        tabsnav={sectiondata.Tabdata.AboutPG.tabsnav}
        tabslist={sectiondata.Tabdata.AboutPG.items}
        secSubtitle={sectiondata.Tabdata.AboutPG.secSubtitle}
        secTitle={sectiondata.Tabdata.AboutPG.secTitle}
      />
 {/* <div class="about">
              <div className="invest-link">
                <a
                  className="btn btn-invest"
                  href="/files/Disclosure_Document.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download our latest Disclosure Document
                </a>
              </div>
              <div className="invest-link">
                <a
                  className="btn btn-invest"
                  href="/files/Agreya Investor charter.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download our Investor Charter
                </a>
              </div>
              <div className="invest-link">
                <a
                  className="btn btn-invest"
                  href="/files/data-on-complaints.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download Data on Complaints
                </a>
              </div>
              </div> */}
      {/* Counter Up */}
      {/* <CounterUp
        secTitle={sectiondata.CounterUp.secTitle}
        secSubtitle={sectiondata.CounterUp.secSubtitle}
        counteritems={sectiondata.CounterUp.items}
        counterbottomtitle={sectiondata.CounterUp.counterbottomtitle}
        counterbottombtn={sectiondata.CounterUp.counterbottombtn}
        counterbottombtnurl={sectiondata.CounterUp.counterbottombtnurl}
      /> */}

      {/* Team */}
      {/* <Team
        secTitle={sectiondata.Teammemberdata.secTitle}
        secSubtitle={sectiondata.Teammemberdata.secSubtitle}
        teammembers={sectiondata.Teammemberdata.teammembers}
      /> */}

      {/* Clients Logo */}
      {/* <Clients sliders={sectiondata.Sliders.clientlogos} />
      <div className="form-white-bg">
        <CtaForm />
      </div> */}

      {/* Footer */}
      {/* <Footer /> */}
    </main>
  );
}
