import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import About_Page from "./pages/About_Page";
import ContactPage from "./pages/ContactPage";
import People_Page from "./pages/People_Page";
import Performance from "./pages/Performance";
import OurServices from "./pages/OurServices";
import InvestmentApproaches from "./pages/InvestmentApproaches";
import FeeSummary from "./pages/FeeSummary";
import FeeSelection from "./pages/FeeSelection";
import RegulatoryDisclosures from "./pages/RegulatoryDisclosures";
import Error from "./pages/Error";
import "./assets/css/style.css";
import "./App.css";

function App() {
  return (
    <React.Fragment>
      <Route>
        <Switch>
          <Route exact path="/" component={Home} />     
          <Route path="/about" component={About_Page} />
          <Route path="/people" component={People_Page} />
          <Route path="/performance" component={Performance} />
          <Route path="/ourservices" component={OurServices} />
          <Route path="/approaches" component={InvestmentApproaches} />
          <Route path="/fee-summary" component={FeeSummary} />
          <Route path="/fee-illustration" component={FeeSelection} />
          <Route
            path="/regulatoryDisclosures"
            component={RegulatoryDisclosures}
          />

          <Route path="/contact" component={ContactPage} />
          <Route component={Error} />
        </Switch>
      </Route>
    </React.Fragment>
  );
}

export default App;
