import { Link } from "react-router-dom";
import { FiChevronRight,FiX } from "react-icons/fi";
import React, { useState } from "react";
import Modal from "react-modal";

export default function Teammembers({ teammembers }) {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalData, setMData] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  function closeModal() {
    setIsOpen(false);
    setSelectedItem(null);
  }

  function openModal(item) {
    setMData(item.data);
    setIsOpen(true);
  }

  function handleItemClick(item) {
    setSelectedItem(item);
    openModal(item);
  }

  function isModal() {
    return window.innerWidth <= 468;
  }

  return (
    <>
      {teammembers.map((item, index) => {
        return (
          <div className="team" key={index}>
            <div className="team-img" onClick={() => handleItemClick(item)}>
              {item.img ? <img src={item.img} alt="Team" style={{ cursor: "pointer" }} /> : ""}
              {item.socials ? (
                <ul className="socials">
                  {item.socials.map((social, i) => (
                    <a key={i} href={social.url}>
                      {social.icon}
                    </a>
                  ))}
                </ul>
              ) : (
                ""
              )}
            </div>
            <div className="team-content">
              {item.name && (
                <div
                  className="name-container"
                  onClick={() => handleItemClick(item)}
                  style={{ cursor: "pointer" }}
                >
                  <h2 className="name">{item.name}</h2>
                </div>
              )}
              {item.designation && <p className="designation">{item.designation}</p>}
              {modalData && (
                <Modal
                
                  style={{
                    overlay: {
                      backgroundColor: "rgba(30, 30, 30, 0.3)",
                      zIndex: "9",
                    },
                    content: {
                      top: isModal() ? "60%" : "50%",
                      left: "50%",
                      right: "auto",
                      bottom: isModal() ? "-10%" : "auto",
                      marginRight: "-50%",
                      transform: "translate(-50%, -50%)",
                      maxWidth: isModal() ? "100%" : "600px",
                      cursor: "pointer",
                    },
                  }}
                  isOpen={modalIsOpen}
                  onRequestClose={closeModal}

                  contentLabel="Example Modal"
                >
                  {isModal() && (
                    <button
                      onClick={closeModal}
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "5%",
                        cursor: "pointer",
                        background: "none",
                        border: "none",
                        padding: "0",
                        outline: "none",
                      }}
                    >
                      <FiX size={24} /> 
                    </button>
                  )}
                  {modalData.map((i, index) => (
                    <p
                      style={{

                        marginBottom: "15px",
                        fontSize: "14px",
                        color: "#677286",
                        fontWeight: 500,
                      }}
                      key={index}
                    >
                      {i}
                    </p>
                  ))}

                </Modal>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
}
