import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  FiChevronDown,
  FiShoppingCart,
  FiSearch,
  FiChevronRight,
} from "react-icons/fi";
import HeaderBar from "../common/HeaderBar";
import { ProductConsumer } from "../../productcontext";
import $ from "jquery";
import MenuCart from "./menucart/MenuCart";
import { FaTimes } from "react-icons/all";
import sectiondata from "../../store/store";

export default function Navbar() {
  const [searchOpen, setsearchOpen] = useState(false);
  const [navOpen, setnavOpen] = useState(false);

  /*====  side-widget-menu  =====*/
  $(document).on(
    "click",
    ".side-menu-wrap .side-menu-ul .sidenav__item",
    function () {
      $(".side-menu-wrap .side-menu-ul .sidenav__item").removeClass("active");
      $(this).toggleClass("active");
    }
  );
  $(document).on(
    "click",
    ".side-menu-wrap .side-menu-ul .sidenav__item.active",
    function () {
      $(".side-menu-wrap .side-menu-ul .sidenav__item.active").removeClass(
        "active"
      );
    }
  );

  $(window).on("scroll", function () {
    // Header Menu Fixed
    if ($(window).scrollTop() > 30) {
      $("body").addClass("header-menu-fixed");
      $(".scroll-d-block").addClass("d-block");
    } else {
      $("body").removeClass("header-menu-fixed");
      $(".scroll-d-block").removeClass("d-block");
    }
  });

  return (
    <>
      <HeaderBar />

      <div className="header-menu-wrapper scroll-d-block"style={{ padding:"10px"}}>
        <div className="header-menu-container "style={{ marginLeft:"0px", maxWidth: "95%"}}>
          <div className="header-menu-flex"style={{margin:"0px"}}>
            <div className="header-menu-left">
              <Link to="/">
                <img
                  src={sectiondata.HeaderData.HeaderMenuBar.logourl}
                  alt="Logo"
                  height="40px"
                  style={{ height:"50px"}}
                />
              </Link>
            </div>
            <div className="header-menu-right"style={{ marginRight:"0px"}}>
              <nav>
                <ul>
                  {sectiondata.HeaderData.HeaderMenuBar.menus.map(
                    (item, index) => {
                      return (
                        <li key={index}>
                          {item.link ? (
                            <a
                              style={{
                                width: "auto",
                                wordWrap: "normal",
                                lineHeight: "1",
                                margin: "20px 0",
                              }}
                              href={item.link}
                              target="_blank"
                            >
                              {item.title}
                            </a>
                          ) : (
                            ""
                          )}
                          {item.path ? (
                            <Link to={item.path}>
                              <span
                                style={{
                                  width: "auto",
                                  wordWrap: "normal",
                                  lineHeight: "1",
                                  margin: "20px 0",
                                }}
                              >
                                {item.title}
                              </span>
                              {/* {item.dropdown ? (
                                <FiChevronDown className="menu-icon" />
                              ) : (
                                ""
                              )} */}
                            </Link>
                          ) : (
                            ""
                          )}
                          {item.dropdown && item.title !== "Investment Approaches" ? (
                            <ul className="dropdown-menu-item">
                              {item.dropdown.map((dp_item, i) => {
                                return (
                                  <li key={i}>
                                    {dp_item.link ? (
                                      <a
                                        style={{
                                          width: "auto",
                                          wordWrap: "normal",
                                          lineHeight: "1",
                                          margin: "20px 0",
                                        }}
                                        href={dp_item.link}
                                        target="_blank"
                                      >
                                        {dp_item.title}
                                      </a>
                                    ) : (
                                      ""
                                    )}
                                    {dp_item.path ? (
                                      <span className="desktop-only">
                                        <a href={dp_item.path} target="_blank">{dp_item.title}</a>
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                    {dp_item.dep_dropdown ? (
                                      <ul className="dropdown-menu-item">
                                        {dp_item.dep_dropdown.map((dp_item_inr, i2) => {
                                          return (
                                            <li key={i2}>
                                              <Link to={dp_item_inr.path}>
                                                {dp_item_inr.title}
                                              </Link>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </li>
                                );
                              })}
                            </ul>
                          ) : (
                            ""
                          )}
                        </li>
                      );
                    }
                  )}
                </ul>
              </nav>
              <div className="header-menu-right-flex">
                {/* <div className="header-menu-cart">
                  <ul>
                    <li>
                      <div className="cart__icon">
                        <Link to="#">
                          <FiShoppingCart />
                        </Link>
                        <span className="cart__item-count">
                          <ProductConsumer>
                            {(value) => {
                              const { cart } = value;
                              if (cart.length > 0) {
                                return <span>{cart.length}</span>;
                              } else {
                                return "0";
                              }
                            }}
                          </ProductConsumer>
                        </span>
                      </div>

                      <MenuCart />
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="search-button"
                        onClick={() => setsearchOpen(!searchOpen)}
                      >
                        {searchOpen ? (
                          <FaTimes className="search-icon" />
                        ) : (
                          <FiSearch className="search-icon" />
                        )}
                      </Link>
                    </li>
                  </ul>
                </div> */}
                <div
                  className={
                    searchOpen
                      ? "header-menu-searchform active"
                      : "header-menu-searchform"
                  }
                >
                  <form action="#">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search by keywords..."
                    />
                    <div className="form-icon">
                      <FiSearch className="search-icon" />
                    </div>
                  </form>
                </div>
                <div
                  className="side-menu-open"
                  onClick={() => setnavOpen(!navOpen)}
                >
                  <span className="menu__bar"></span>
                  <span className="menu__bar"></span>
                  <span className="menu__bar"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
<div className={navOpen ? "side-nav-container active" : "side-nav-container"}>
  <div className="humburger-menu">
    <div
      className="humburger-menu-lines side-menu-close"
      onClick={() => setnavOpen(!navOpen)}
    ></div>
  </div>
  <div className="side-menu-wrap">
    <ul className="side-menu-ul">
      {sectiondata.HeaderData.HeaderMenuBar.menus.map((item, index) => (
        <li className="sidenav__item" key={index}>
          {item.path || item.link ? (
            <>
              {item.link ? (
                <a href={item.link} onClick={() => setnavOpen(false)}>
                  {item.title}
                </a>
              ) : null}
              {item.path ? (
                <Link to={item.path} onClick={() => setnavOpen(false)}>
                  {item.title}
                </Link>
              ) : null}
            </>
          ) : null}
          {item.dropdown ? <span className="menu-plus-icon"></span> : null}
          {item.dropdown ? (
            <ul className="side-sub-menu">
              {item.dropdown.map((dp_item, i) => (
                <li key={i}>
                  {dp_item.link ? (
                    <a href={dp_item.link} onClick={() => setnavOpen(false)}>
                      {dp_item.title}
                    </a>
                  ) : null}
                  {dp_item.path ? (
                    <Link to={dp_item.path} onClick={() => setnavOpen(false)}>
                      {dp_item.title}
                    </Link>
                  ) : null}
                </li>
              ))}
            </ul>
          ) : null}
        </li>
      ))}
    </ul>
  </div>
</div>
</>
  );
}   