import React, { useMemo } from "react";
import SectionsTitle from "../common/SectionsTitle";
import { FiChevronRight } from "react-icons/fi";
import ContactSidebar from "./ContactSidebar";
// import Maps from '../contact/Maps'
import CtaNewsletter from "../../sections/CtaNewsletter";
import sectiondata from "../../store/store";
import SocialProfile from "../widgets/SocialProfile";
import Table from "../../components/common/Table";
import ComplaintsRedressalMatrix from "../../assets/jsons/ComplaintsRedressalMatrix.json";
export default function ContactDetails() {
  const columns = React.useMemo(
    () => [
      {
        Header: "Level",
        accessor: "Level",
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ value }) => <div dangerouslySetInnerHTML={{ __html: value }} />,
      },
      {
        Header: "Details",
        accessor: "contact",
      },
    ],
    []
  );

  return (
    <section className="contact-form-wrapper">
      <div className="container">
        <div className="contact-form-map-grid">
          <div className="left">
            <Table
              title="Complaints Redressal Matrix"
              columns={columns}
              data={ComplaintsRedressalMatrix.map(row => ({
                ...row,
                contact: <div dangerouslySetInnerHTML={{ __html: row.contact }} />,
              }))}
            />
          </div>
          <div className="right">
            <ContactSidebar />
          </div>
        </div>
      </div>
    </section>
  );
}
