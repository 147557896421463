import React from "react";
import Navbar from "../components/common/Navbar";
import Breadcrumb from "../components/common/Breadcrumb";
import Footer from "../components/other/Footer";
import SimpleTab from "../components/tabs/SimpleTab";
import sectiondata from "../store/store";

function OurServices() {
  return (
    <main>
      <header className="header-area">
        <Navbar />
      </header>
      {/* Breadcrumb */}
      {/* <Breadcrumb title="Our Services." /> */}
      <div
        style={{
          margin: "10% auto",
        }}
      >
        <SimpleTab
          secTitle={sectiondata.Tabdata.OurServices.secTitle}
          secSubtitle={sectiondata.Tabdata.OurServices.secSubtitle}
          tabslist={sectiondata.Tabdata.OurServices.items}
          tabsnav={sectiondata.Tabdata.OurServices.tabsnav}
        />
      </div>
      {/* Footer */}
      {/* <Footer /> */}
    </main>
  );
}

export default OurServices;
