import React from "react";
import singleimg from "../../assets/images/img16.jpg";
import img from "../../assets/images/img4.jpg";
import avata1 from "../../assets/images/testi-img1.jpg";
import { Link } from "react-router-dom";
import ReactSpeedometer from "react-d3-speedometer"
import {
  FiMessageCircle,
  FiCheckCircle,
  FiChevronsLeft,
  FiChevronsRight,
  FiThumbsUp,
  FiMeh,
  FiChevronRight,
} from "react-icons/fi";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaGooglePlusG,
  FaReply,
  FaQuoteLeft,
} from "react-icons/fa";
import BlogSidebar from "./BlogSidebar";

const lists = [
  {
    list: "Sed do eiusmod tempor incididunt ut",
  },
  {
    list: "Exercitation ullamco laboris nis.",
  },
  {
    list: "Quis nostrud exer citation.",
  },
  {
    list: "Andomised words which don't look",
  },
  {
    list: "Commodo consequat duis autex.",
  },
  {
    list: "Andomised words which don't look",
  },
  {
    list: "Pharetra dui, nec tincidunt ante",
  },
];
export default function BlogDetailsContent({ blog, speedometerValue, customSegmentLabels ,segmentColors,isMobile}) {
  return (
    <section className="blog-details-wrapper">
      <div className="container">
        <div className="blog-details-grid d-grid">
          <div className="blog-details-left">
            <div className="blog-details-contents">
              <h3 style ={{font:"bold"}}>{blog.title}</h3>
              <div className="author-benchmark-container"style={{display:"flex",width:"100%",height:"90%",margin:"-10px"}}>
                <div className="author-container"style={{width: isMobile ? "fit-content" : "250%",margin:"20px",justifyContent:"left"}}>
                  <p style={{ fontWeight: "bolder", color: "#233d63", margin: 0 }}>
                    {blog.author}
                  </p>
                  <p style={{ fontWeight: "bolder", color: "#233d63", margin: 0 }}>
                    {blog.benchmark}
                  </p>
                </div>
                <div className="speedometer-container">
                  <ReactSpeedometer
                    width={270}
                    needleHeightRatio={0.7}
                    ringWidth={30}
                    textColor={'#033132'}
                    fontWeight={'2px'}
                    value={speedometerValue}
                    currentValueText="Riskometer"
                    customSegmentLabels={customSegmentLabels}
                    segmentColors={segmentColors}
                    needleTransitionDuration={2000}
                    segments={5}
                    
                  />
      
                </div>
              </div>
              <p
                style={{
                  marginTop:"-135px",
                  maxWidth: "100%",
                  textAlign: "justify",
                }}
              >
                {blog.content}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}