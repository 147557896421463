import React from "react";
import Navbar from "../components/common/Navbar";
import Breadcrumb from "../components/common/Breadcrumb";
import Footer from "../components/other/Footer";
import Table from "../components/common/Table";
import AgreyasPerformancenumbers from "../assets/jsons/AgreyasPerformancenumbers.json";
import sectiondata from "../store/store";
function Performance() {
  const columns = React.useMemo(
    () => [
      {
        Header: "Investment Approach vis-à-vis Benchmark",
        accessor: "InvestmentApproachvis-à-visBenchmark",
      },
      {
        Header: "AUM(INR Crs)",
        accessor: "AUM(INRCrs)",
      },
      {
        Header: "1 month",
        accessor: "1month",
      },
      {
        Header: "3 month",
        accessor: "3month",
      },
      {
        Header: "6 months",
        accessor: "6months",
      },
      {
        Header: "1 year",
        accessor: "1year",
      },
      {
        Header: "3 years",
        accessor: "3years",
      },
      {
        Header: "Since Inception",
        accessor: "SinceInception",
      },
    ],
    []
  );
  // const data = React.useMemo(() => sectiondata.performanceTable, []);
  return (
    <main>
      <header className="header-area">
        <Navbar />
      </header>
      {/* Breadcrumb */}
      {/* <Breadcrumb title="Performance." /> */}
      <div>
        <Table
          columns={columns}
          data={AgreyasPerformancenumbers}
          title="Agreya’s Performance numbers"
        />
      </div>
      {/* Footer */}
      {/* <Footer /> */}
    </main>
  );
}

export default Performance;
