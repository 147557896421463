import React from "react";
import { useTable } from "react-table";

function Table({ columns, data, title }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <div>
      {title ? <h2 style={{ margin: "30px auto",textAlign: "center" }}>{title}</h2> : ""}
      <table
        {...getTableProps()}
        style={{
          borderSpacing: 0,
          borderCollapse: "collapse",
          border: "1px solid black",
          margin: "auto",
        }}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, columnIndex) => (
                <th
                  {...column.getHeaderProps()}
                  style={{
                    margin: 0,
                    padding: "1rem",
                    borderBottom: "1px solid black",
                    borderRight:
                      columnIndex === headerGroup.headers.length - 1
                        ? "0"
                        : "1px solid black",
                    width: column.Header === "Name" ? "300px" : "auto", // Set width for "Name" column
                  }}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                style={{
                  margin: 0,
                  padding: "0.5rem",
                }}
              >
  
                {row.cells.map((cell, cellIndex) => (
                  <td
                    {...cell.getCellProps()}
                    style={{
                      margin: 0,
                      padding: "0.5rem",
                      borderBottom: "1px solid black",
                      borderRight:
                        cellIndex === row.cells.length - 1
                          ? "0"
                          : "1px solid black",
                    }}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
