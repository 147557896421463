import React from "react";
import Navbar from "../components/common/Navbar";
import Breadcrumb from "../components/common/Breadcrumb";
import Footer from "../components/other/Footer";
import Table from "../components/common/Table";
import ComplaintsRedressalMatrix from "../assets/jsons/ComplaintsRedressalMatrix.json";
import workFlow from "../assets/images/workflow.png";
import table1 from "../assets/jsons/table1.json";
import table2 from "../assets/jsons/table2.json";
import table3 from "../assets/jsons/table3.json";


function RegulatoryDisclosures() {
  const columns = React.useMemo(
    () => [
      {
        Header: "Level",
        accessor: "Level",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Details",
        accessor: "contact",
      },
    ],
    []
  );
  const columns2 = React.useMemo(
    () => [
      {
        Header: "Sr No",
        accessor: "Sr No",
      },
      {
        Header: "Received from",
        accessor: "Received from",
      },
      {
        Header: "Pending at the end of last month",
        accessor: "Pending at the end of last month",
      },
      {
        Header: "Received",
        accessor: "Received",
      },
      {
        Header: "Resolved",
        accessor: "Resolved",
      },
      {
        Header: "Total Pending",
        accessor: "Total Pending",
      },
      {
        Header: "Pending complaints>3 months",
        accessor: "Pending complaints>3 months",
      },
      {
        Header: "Average resolution time (in days)",
        accessor: "Average resolution time (in days)",
      },
    ],
    []
  );
  const columns3 = React.useMemo(
    () => [
      {
        Header: "Sr No",
        accessor: "Sr No",
      },
      {
        Header: "Month",
        accessor: "Month",
      },
      {
        Header: "Carried forward from previous month",
        accessor: "Carried forward from previous month",
      },
      {
        Header: "Received",
        accessor: "Received",
      },
      {
        Header: "Resolved",
        accessor: "Resolved",
      },
      {
        Header: "Pending",
        accessor: "Pending",
      },
    ],
    []
  );

  const columns4 = React.useMemo(
    () => [
      {
        Header: "Sr No",
        accessor: "Sr No",
      },
      {
        Header: "Month",
        accessor: "Month",
      },
      {
        Header: "Carried forward from previous month",
        accessor: "Carried forward from previous month",
      },
      {
        Header: "Received",
        accessor: "Received",
      },
      {
        Header: "Resolved",
        accessor: "Resolved",
      },
      {
        Header: "Pending",
        accessor: "Pending",
      },
    ],
    []
  );

  return (
    <main>
      <header className="header-area">
        <Navbar />
      </header>
      {/* Breadcrumb */}
      {/* <Breadcrumb title="Regulatory Disclosures." /> */}
      <div style={{ marginTop: "15%" }}>
        {/* <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={workFlow} alt="Work Flow" />
        </div> */}
        {/* <div class="invest-link" style={{ maxWidth: "700px", margin: "auto" }}>
            <a class="btn btn-invest" href="/files/Disclosure_Document.pdf" target="_blank">Download our
                latest Disclosure Document</a>
        </div>
        <div class="invest-link" style={{ maxWidth: "700px", margin: "auto" }}>
            <a class="btn btn-invest" href="/files/data-on-complaints.pdf" target="_blank">Download Data on Complaints</a>
        </div>
        <div class="invest-link" style={{ maxWidth: "700px", margin: "auto" }}>
            <a class="btn btn-invest" href="/files/agreya-investor-charter.pdf" target="_blank">Download our Investor Charter</a>
        </div> */}
        
        
      
        <div style={{ marginTop: "15%",maxWidth: "700px", margin: "auto" }}>
          <Table
            title="Data for the month ending-January 31,2024"
            columns={columns2}
            data={table1}
          />
          <Table
            title="Trend of monthly disposal of complaints"
            columns={columns3}
            data={table2}
          />
          <Table
            title="Trend of annual disposal of complaints"
            columns={columns4}
            data={table3}
          />
          <Table
            title="Complaints Redressal Matrix"
            columns={columns}
            data={ComplaintsRedressalMatrix}
          />
        </div>
        {/* <div class="about">
              <div className="invest-link">
                <a
                  className="btn btn-invest"
                  href="/files/Disclosure_Document.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download our latest Disclosure Document
                </a>
              </div>
              <div className="invest-link">
                <a
                  className="btn btn-invest"
                  href="/files/Agreya Investor charter.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download our Investor Charter
                </a>
              </div>
              <div className="invest-link">
                <a
                  className="btn btn-invest"
                  href="/files/data-on-complaints.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download Data on Complaints
                </a>
              </div>
              </div> */}
      

      </div>
      {/* Footer */}
      {/* <Footer /> */}
    </main>
  );
}

export default RegulatoryDisclosures;
